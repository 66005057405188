import React, { useEffect, useState } from 'react';
import { Avatar, Badge, Button, Card, CardContent, Divider, Grid, styled, TextField, Typography, CardActions } from '@mui/material';
import useStyles from '../../styles/landingPage';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { ReactComponent as GoogleIcon } from "../../components/icons/ico_google.svg";
import { ReactComponent as FacebookIcon } from "../../components/icons/ico_facebook.svg";
import { ReactComponent as TwitterIcon } from "../../components/icons/ico_twitter.svg";
import { ReactComponent as LEFTPINKICON } from "../../components/icons/left_landing_alert.svg";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { firebaseConfig } from '../../configs';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { connect } from 'react-redux';
import * as happyClientsAction from '../../redux/action/happyclientsAction';
import * as registerAction from '../../redux/action/registerAction';
import * as loginAction from '../../redux/action/loginAction';
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
const SmallAvatar = styled(Avatar)(({ theme }) => ({
	width: 70,
	height: 70,
}));
const SmallAvatar2 = styled(Avatar)(({ theme }) => ({
	width: 60,
	height: 60,
}));
const SmallAvatarTab = styled(Avatar)(({ theme }) => ({
	width: 50,
	height: 50,
}));
const SmallAvatar3 = styled(Avatar)(({ theme }) => ({
	width: 70,
	height: 70,
}));
const SmallAvatar4 = styled(Avatar)(({ theme }) => ({
	width: 50,
	height: 50,
}));
const SmallAvatarHeart = styled(Avatar)(({ theme }) => ({
	width: 45,
	height: 45,
	border: `2px solid ${theme.palette.background.paper}`
}));
const SmallAvatarClose = styled(Avatar)(({ theme }) => ({
	width: 45,
	height: 45,
	border: `2px solid ${theme.palette.background.paper}`,
}));

const Banner = ({ loginStatus, socialLoginService, loginService, registerStatus, happyClientsData, getHappyClientsService, verifyUniqueUser, uniqueStatusNull, is_username_exist, registerService }) => {
	const history = useHistory();
	const classes = useStyles();
	const { t } = useTranslation();
	const app = initializeApp(firebaseConfig);
	const auth = getAuth();
	const twitterProvider = new TwitterAuthProvider();
	const [dob, setdob] = useState(null);
	const [maxDob, setMaxDOB] = useState(new Date(''));
	const [registrationData, setRegistrationData] = useState({
		email: '',
		gender: 'male',
		lookingfor: 'men',
		username: '',
		password: ''
	}
	)
	const [loadCardContent, setCardContentType] = useState(1)
	const [regAlert, setshowRegAlert] = useState(false)
	const [happyClients, setHappyClients] = useState([])
	const [isActive, setIsActive] = useState(2)
	const [currentUserData, setCurrentUserData] = useState({ name: '', lookingFor: '' })
	const [socialData, setSocialData] = useState({ uniqueId: '', tokenId: "", accessToken: "" })
	const [socialType, setSocialType] = useState("None")
	const [splChrExist, setSpecialCharacterExist] = useState(false)
	const [usernameErr, setUserNameErr] = useState(0)
	const [passwordErr, setPasswordErr] = useState(0)
	const [userNameExist, setUsernameExist] = useState(null);
	const [showPassword, setShowPassword] = useState(false)
	useEffect(() => {
		getHappyClientsService()
	}, [])

	useEffect(() => {
		if (happyClientsData) {
			let userImages = [], mainPic;
			for (var i = 0; i < happyClientsData.length; i++) {
				if (i === 2) {
					setCurrentUserData({ name: happyClientsData[i].username, lookingFor: happyClientsData[i].looking_for })
					mainPic = happyClientsData[i].pictures
					userImages.push(happyClientsData[i].mobile_profile_image['1024'])
				} else if (i === 6) {
					break;
				} else {
					userImages.push(happyClientsData[i].mobile_profile_image['1024'])
				}
			}
			userImages.push(mainPic)
			setHappyClients(userImages)

		}


	}, [happyClientsData])
	const responseGoogle = (response) => {
		console.log(response);
		setRegistrationData({ ...registrationData, email: response.profileObj?.email });
		setSocialData({ uniqueId: response.profileObj.googleId, tokenId: response.tokenId, accessToken: response.Cc.accessToken })
		setSocialType('google')
		let formData = new FormData();
		formData.append("email", "")
		formData.append("unique_id", response.profileObj.googleId)
		socialLoginService(formData)
		//setCardContentType(2)
		// let data = new FormData();
		// data.append('unique_id', response.profileObj?.googleId)
		// props.socialLoginService(data)
	};

	const responseError = (response) => {
		console.log(response);
	};

	const responseFacebook = (response) => {
		console.log("FbRes", response);
		let token = response.accessToken;
		axios.get(`https://graph.facebook.com/me?fields=id,name,email&access_token=${response.accessToken}`)
			.then((response) => {
				if (response.data) {
					console.log("fbres2", response.data);
					setRegistrationData({ ...registrationData, email: response.data.email });
					setSocialData({ uniqueId: response.data.id, tokenId: response.data.id, accessToken: token })
					//setCardContentType(2)
					setSocialType('facebook')
					let formData = new FormData();
					formData.append("email", "")
					formData.append("unique_id", response.data.id)
					socialLoginService(formData)
				}
			})
			.catch((error) => {
				console.log(error);
			})
		// let data = new FormData();
		// data.append('unique_id', response.id)
		// props.socialLoginService(data)
	}

	const authHandler = (provider) => {
		signInWithPopup(auth, provider)
			.then((result) => {
				// This gives you a the Twitter OAuth 1.0 Access Token and Secret.
				// You can use these server side with your app's credentials to access the Twitter API.
				//const credential = TwitterAuthProvider.credentialFromResult(result);
				// The signed-in user info.
				console.log(result.user);
				if (result.user) {
					const user = result.user;
					setRegistrationData({ ...registrationData, email: result.user.email });
					setSocialData(result.user)
					setSocialData({ uniqueId: user.uid, tokenId: '', accessToken: user.accessToken })
					setSocialType('twitter')
					let formData = new FormData();
					formData.append("email", "")
					formData.append("unique_id", user.uid)
					socialLoginService(formData)
				}
				//   const user = result.user;
				//   let data = new FormData();
				//   data.append('unique_id', user.uid)
				//   props.socialLoginService(data)
			}).catch((error) => {
				// Handle Errors here.
				const errorCode = error.code;
				const errorMessage = error.message;
				// The email of the user's account used.
				const email = error.customData.email;
				// The AuthCredential type that was used.
				const credential = TwitterAuthProvider.credentialFromError(error);
				console.log(error);
				// ...
			});
	};
	useEffect(() => {

		if (loginStatus === 'Account not exists. Kindly proceed for the Signup') {
			setCardContentType(2)
		}
	}, [loginStatus]);

	const renderCardContents = (requireContent) => {
		if (requireContent === 1) {
			return (<CardContent className={classes.regCardColor}>
				<Grid container>
					<Grid item xs={0.8} sx={{ mt: 3 }}></Grid>
					<Grid item xs={11.2} display='flex' justifyContent={'start'} sx={{ mb: 1, mt: 2 }}>
						<Typography variant="p" sx={{ fontWeight: 'bold' }}>{t("regCard1Primary")} </Typography>
					</Grid>
					<Grid item xs={0.8}></Grid>
					<Grid item xs={11.2} display='flex' justifyContent={'start'} sx={{ mb: 2 }}>
						<Typography variant="p">{t("regCard1Secondary")}</Typography>
					</Grid>
					<Grid item xs={12} display='flex' justifyContent={'center'} sx={{ mt: 2 }}>
						<FacebookLogin
							appId="699443281105756"
							autoLoad={false}
							callback={responseFacebook}
							render={(renderProps) => (
								<Button sx={{ backgroundColor: "#3b5998", textTransform: 'none', '&:hover': { backgroundColor: "#3b5998", } }}
									role="button"
									onClick={renderProps.onClick}>
									<FacebookIcon />&nbsp; Sign up via Facebook
								</Button>
							)}
						/>
					</Grid>
					<Grid item xs={12} display='flex' justifyContent={'center'} sx={{ mt: 2 }}>

						<Button sx={{ backgroundColor: "rgb(51, 204, 255);", textTransform: 'none', '&:hover': { backgroundColor: "rgb(51, 204, 255);", } }}
							role="button" onClick={() => authHandler(twitterProvider)}>
							<TwitterIcon />&nbsp; Sign up via Twitter</Button>
					</Grid>
					<Grid item xs={12} display='flex' justifyContent={'center'} sx={{ mt: 2 }}>
						<GoogleLogin
							clientId="564348909029-f0062k8qg8fe5u20lplb2dhguch636fu.apps.googleusercontent.com"
							render={(renderProps) => (
								<Button sx={{ backgroundColor: "#FFFFFF", color: 'black', textTransform: 'none' }}
									role="button" onClick={renderProps.onClick}>
									<GoogleIcon />&nbsp; Sign up via Google</Button>
							)}
							buttonText="Login"
							onSuccess={responseGoogle}
							onFailure={responseError}
							cookiePolicy={'single_host_origin'}
						/>
					</Grid>
					<Grid item xs={12} display='flex' justifyContent={'space-between'} alignItems={'center'} sx={{ mt: 4 }}>
						<Divider sx={{ width: 120 }} />
						<Typography variant='p'>Or</Typography>
						<Divider sx={{ width: 120 }} />
					</Grid>
					<Grid item xs={12} display='flex' justifyContent={'center'} sx={{ mt: 4 }}>
						<Button variant="contained" color="primary">
							<Link
								to="#"
								style={{ textDecoration: 'none', color: '#fff', fontWeight: "bold" }}
								onClick={() => setCardContentType(2)}
							>
								{t("regCard1Button")}
							</Link>
						</Button>
					</Grid>
				</Grid>
			</CardContent>)
		} else if (requireContent === 2) {
			return (
				<CardContent className={classes.regCardColor}>
					<Grid container>
						<Grid item xs={0.8} sx={{ mt: 3 }}></Grid>
						<Grid item xs={11.2} display='flex' justifyContent={'start'} sx={{ mb: 1, mt: 2 }}>
							<Typography variant="p" sx={{ fontWeight: 'bold' }}>{t("regCard2Primary")} </Typography>
						</Grid>
						<Grid item xs={0.8}></Grid>
						<Grid item xs={11.2} display='flex' justifyContent={'start'} sx={{ mb: 2 }}>
							<Typography variant="p">{t("regCard2Secondary")}</Typography>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', background: 'url("/assets/images/step1.png") no-repeat', width: '100%', height: '26px' }}>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', ml: 1.2, mt: 1 }}>
							<Typography variant='h6' sx={{ fontWeight: 'bold' }}>{t("regCard2GenderMen/Women")}  </Typography>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', ml: 1.2, mt: 1 }}>
							<Button variant={registrationData.gender === 'male' ? 'contained' : 'outlined'}
								color='primary'
								name="gender"
								value="male"
								onClick={(e) => handleUserData(e)}
								sx={{ borderRadius: '5px' }}>
								{t("Male")}
							</Button>
							<Button variant={registrationData.gender === 'female' ? 'contained' : 'outlined'}
								color='primary'
								name="gender"
								value="female"
								onClick={(e) => handleUserData(e)}
								sx={{ ml: 1, borderRadius: '5px' }}>
								{t("Female")}
							</Button>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', ml: 1.2, mt: 1 }}>
							<Typography variant='h6' sx={{ fontWeight: 'bold' }}>{t("regCard2LookingMen/Women")}  </Typography>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'start', ml: 1.2, mt: 1 }}>
							<Button variant={registrationData.lookingfor === 'men' ? 'contained' : 'outlined'}
								color='primary'
								name="lookingfor"
								value="men"
								onClick={(e) => handleUserData(e)}
								sx={{ borderRadius: '5px', width: 139 }}>
								{t("Men")}
							</Button>
							<Button variant={registrationData.lookingfor === 'women' ? 'contained' : 'outlined'}
								color='primary'
								name="lookingfor"
								value="women"
								onClick={(e) => handleUserData(e)}
								sx={{ ml: 1, borderRadius: '5px', width: 140 }}>
								{t("Women")}
							</Button>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', ml: 1.2, mt: 3 }}>
							<Button variant='contained'
								color='primary'
								onClick={() => changeCardContents(3)}
								sx={{ mt: 3, }}
								endIcon={<EastIcon />}>
								{t("nextButton")}
							</Button>
						</Grid>
					</Grid>

				</CardContent>
			)
		} else if (requireContent === 3) {
			return (
				<CardContent className={classes.regCardColor}>
					<Grid container>
						<Grid item xs={0.8} sx={{ mt: 3 }}></Grid>
						<Grid item xs={11.2} display='flex' justifyContent={'start'} sx={{ mb: 1, mt: 2 }}>
							<Typography variant="p" sx={{ fontWeight: 'bold' }}>{t("regCard3Primary")} </Typography>
						</Grid>
						<Grid item xs={0.8}></Grid>
						<Grid item xs={11.2} display='flex' justifyContent={'start'} sx={{ mb: 2 }}>
							<Typography variant="p">{t("regCard3Secondary")}</Typography>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', background: 'url("/assets/images/step2.png") no-repeat', width: '100%', height: '26px' }}>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1, alignItems: 'center' }}>

							<input
								placeholder={t("EnterUserName")}
								className={`${classes.textInput} ${userNameExist ? `${classes.usernameErr}` : userNameExist === false ? `${classes.usernameSuccess}` : ""}`}
								name="username"
								onChange={(e) => handleUserData(e)}
								onBlur={(e) => validateUserData(e)}
							/>

						</Grid>
						{userNameExist ? <Typography variant='p' color="error" sx={{ mt: 0 }}>{t("usernameAlreadyExist")}</Typography> : ""}
						{usernameErr === 3 ? <Typography variant='p' color="error" sx={{ mt: 0 }}>{t("usernameRequired")}</Typography> : ""}
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
							<div className={classes.IconInside}>
								<input placeholder={t("enterYourpassword")}
									className={classes.textInputpassword}
									name="password"
									onChange={(e) => handleUserData(e)}
									type={showPassword ? 'text' : 'password'} />
								{!showPassword ? <VisibilityOffIcon className={classes.iconEye} onClick={() => setShowPassword(true)} /> : <VisibilityIcon className={classes.iconEye} onClick={() => setShowPassword(false)} />}
							</div>
						</Grid>
						{passwordErr === 1 ?
							<Typography variant='p' color="error" sx={{ mt: 0 }}>{t("passwordShouldcontainAtleast8Character")}</Typography> : ""}
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', ml: 1.2, mt: 0 }}>
							<Button

								onClick={() => changeCardContents(2)}
								sx={{ mt: 3, color: "#404040" }}
								startIcon={<WestIcon />}>
								{t("backButton")}
							</Button>
							<Button variant='contained'
								color='primary'
								onClick={() => submitUserData(4)}
								sx={{ mt: 3 }}
								endIcon={<EastIcon />}>
								{t("nextButton")}
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			)
		} else if (requireContent === 4) {
			return (
				<CardContent className={classes.regCardColor}>
					<Grid container>
						<Grid item xs={0.8} sx={{ mt: 3 }}></Grid>
						<Grid item xs={11.2} display='flex' justifyContent={'start'} sx={{ mb: 1, mt: 2 }}>
							<Typography variant="p" sx={{ fontWeight: 'bold' }}>{t("regCard4Primary")} </Typography>
						</Grid>
						<Grid item xs={0.8}></Grid>
						<Grid item xs={11.2} display='flex' justifyContent={'start'} sx={{ mb: 2 }}>
							<Typography variant="p">{t("regCard4Secondary")}</Typography>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', background: 'url("/assets/images/step3.png") no-repeat', width: '100%', height: '26px' }}>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
							<DesktopDatePicker
								className='DOBField'
								value={dob}
								maxDate={maxDob}
								onChange={(newValue) => setdob(newValue)}
								renderInput={(params) => <TextField {...params} />}
							/>
						</Grid>
						<Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end', ml: 1.2, mt: 15 }}>
							<Button

								onClick={() => changeCardContents(3)}
								sx={{ color: "#404040" }}
								startIcon={<WestIcon />}>
								{t("backButton")}
							</Button>
							<Button variant='contained'
								color='primary'
								onClick={() => createUser()}
								sx={{}}
								endIcon={<EastIcon />}>
								{t("nextButton")}
							</Button>
						</Grid>
					</Grid>
				</CardContent>
			)
		}
	}
	const changeCardContents = (nextCard) => {
		setCardContentType(nextCard)
	}
	const validateUserData = (e) => {
		let data = new FormData();
		data.append('username', registrationData.username);
		var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		if (registrationData.username.length >= 6 && !registrationData.username.match(format)) {
			verifyUniqueUser(data);
			setUserNameErr(0)
		} else if (registrationData.username.length >= 6 && registrationData.username.match(format)) {
			//having spl character
			setUserNameErr(1)
		} else {
			//length not reached
			setUserNameErr(2)
		}
	}
	const submitUserData = (nextCard) => {
		// debugger;
		// let validEmail = null;
		// if (socialType === 'None') {
		// 	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(registrationData.email)) {
		// 		validEmail = true;
		// 		setEmailErr(false)
		// 	} else {
		// 		validEmail = false;
		// 		setEmailErr(true)
		// 	}
		// }
		if (userNameExist === false && registrationData.password.length >= 6) {
			setCardContentType(nextCard)
			setPasswordErr(0)
			setUserNameErr(0)
		} else if (registrationData.password.length < 6 && registrationData.username.length < 6) {
			setPasswordErr(1)
			setUserNameErr(3)
		} else if (registrationData.password.length >= 6 && registrationData.username.length < 6) {
			setPasswordErr(0)
			setUserNameErr(3)
		} else if (registrationData.password.length < 6 && registrationData.username.length >= 6) {
			setPasswordErr(1)
			setUserNameErr(0)
		}

	}

	useEffect(() => {
		if (is_username_exist) {
			setUsernameExist(true);
			uniqueStatusNull()
		} else if (is_username_exist === false) {
			setUsernameExist(false);
			uniqueStatusNull()
		}
	}, [is_username_exist]);

	useEffect(() => {
		var maxBirthdayDate = new Date();
		maxBirthdayDate.setFullYear(maxBirthdayDate.getFullYear() - 18);
		maxBirthdayDate.setMonth(
			maxBirthdayDate.getMonth(),
			maxBirthdayDate.getDate()
		);
		setdob(maxBirthdayDate);
		setMaxDOB(maxBirthdayDate);
	}, []);
	useEffect(() => {
		if (dob === '') {
			setdob(maxDob);
		}
	}, [dob]);
	const handleUserData = (e) => {
		let { name, value } = e.target
		setRegistrationData({ ...registrationData, [name]: value });
	}
	const createUser = () => {
		var mm = dob.getMonth() + 1;
		var dd = dob.getDate();
		var yyyy = dob.getUTCFullYear();
		const requestData = new FormData();
		requestData.append('username', registrationData.username);
		requestData.append('email', registrationData.email);
		requestData.append('password', registrationData.password);
		requestData.append('dob', `${yyyy}-${mm < 9 ? '0' + mm : mm}-${dd}`);
		requestData.append('gender', registrationData.gender);
		requestData.append('country_code', '');
		requestData.append('city_code', '');
		requestData.append('state_code', '');
		requestData.append('looking_for', registrationData.lookingfor);
		requestData.append('reference', socialType);
		requestData.append('profile_pic', '');
		requestData.append('martial_status', '');
		requestData.append('unique_id', socialData.uniqueId);
		requestData.append('access_token', socialData.accessToken);
		requestData.append('tokenId', socialData.tokenId);
		requestData.append('about_me', '');
		console.log('dob', `${yyyy}-${mm < 9 ? '0' + mm : mm}-${dd}`);
		registerService(requestData)
	}
	useEffect(() => {
		if (registerStatus === 1 || registerStatus === '1') {
			let requestData = new FormData();
			requestData.append('email', registrationData.username);
			requestData.append('password', registrationData.password);
			loginService(requestData)
		}
	}, [registerStatus]);

	useEffect(() => {
		if (localStorage.getItem('userToken') != null) {
			history.push('/newest-members');
		}
	}, [localStorage.getItem('userToken')]);

	const regAlertCard = () => {
		return (
			<Card sx={{ maxWidth: 250, height: 150 }}>
				<CardContent sx={{ pb: 0 }}>
					<Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
						{t("oneClickAway")}
					</Typography>

				</CardContent>
				<CardActions sx={{ pt: 0 }}>
					<LEFTPINKICON /><Typography sx={{ fontSize: 14, color: "#fe456e", ml: 5 }}>
						<span>{t("registerHere")}</span>
					</Typography>
				</CardActions>
			</Card>
		)
	}
	const changeCircleImage = (e, activeCircle) => {
		e.stopPropagation();
		if (activeCircle > 6) {
			setshowRegAlert(!regAlert)
		}
		if (activeCircle <= 6) {

			setIsActive(activeCircle)
			happyClients[2] = happyClientsData[activeCircle].pictures;
			setCurrentUserData({ name: happyClientsData[activeCircle].username, lookingFor: happyClientsData[activeCircle].looking_for })
		}


	}
	const renderWeb = () => {
		return (
			<Grid container>
				<Grid item xs={5} sm={3} md={3} lg={5} sx={{ display: 'flex', alignItems: 'center' }}>
					<Card className={classes.cardSection}>
						{renderCardContents(loadCardContent)}
					</Card>
				</Grid>
				<Grid item xs={7} sm={9} md={9} lg={7} display='flex' justifyContent={'center'} >
					<Grid container>
						{/* <Grid item xs={9} lg={4} md={12} sm={12} display='flex' justifyContent={'end'}>

						</Grid> */}
						{/* <Grid item xs={9} lg={5} md={12} sm={12} display='flex' justifyContent={'end'} alignItems="end">
							<Avatar
								alt="Remy Sharp"
								src={happyClients[0]}
								sx={{ width: 50, height: 50, border: isActive === 0 ? "2px solid #aa5eed" : '2px solid white' }}
								onClick={(e) => changeCircleImage(e, 0)}
							/>
						</Grid> */}
						{/* <Grid item xs={0} lg={3} md={0} sm={0} /> */}
						{/* <Grid item xs={1} lg={1} md={1} display='flex' justifyContent={'end'} alignItems='center'> */}
						{/* <Avatar
								alt="Remy Sharp"
								src={happyClients[3]}
								sx={{ width: 50, height: 50, border: isActive === 3 ? "2px solid #aa5eed" : '2px solid white' }}
								onClick={(e) => changeCircleImage(e, 3)}
							/> */}
						{/* </Grid> */}
						<Grid item xs={9} lg={9} md={11} sm={11} display='flex' justifyContent={'center'} sx={{ mb: 7 }}>
							<Badge
								onClick={(e) => changeCircleImage(e, 5)}
								overlap="circular"
								anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
								badgeContent={
									<SmallAvatar alt="Remy Sharp" src={happyClients[5]} sx={{ marginBottom: '250px', marginLeft: '100px', border: isActive === 5 ? "2px solid #aa5eed" : '2px solid white' }} />
								}
							>
								{/* <Badge
									onClick={(e) => changeCircleImage(e, 7)}
									overlap="circular"
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									badgeContent={
										<SmallAvatarHeart alt="Remy Sharp" src="/assets/icons/landing_heart.svg" sx={{ marginTop: '90px', marginRight: '170px' }} />
									}
								> */}
								<Badge
									onClick={(e) => changeCircleImage(e, 4)}
									overlap="circular"
									anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
									badgeContent={
										<SmallAvatar2 alt="Remy Sharp" src={happyClients[4]} sx={{ border: isActive === 4 ? "2px solid #aa5eed" : '2px solid white' }} />
									}
								>
									{/* <Badge
											onClick={(e) => changeCircleImage(e, 8)}

											overlap="circular"
											anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
											badgeContent={
												<img src="/assets/images/ico-like.png" alt=''/>
												// <SmallAvatarClose alt="Remy Sharp" src="/assets/images/ico-like.png" sx={{ marginTop: '100px', marginLeft: '250px', }} />
											}
										> */}
									<Badge
										onClick={(e) => changeCircleImage(e, 1)}
										overlap="circular"
										anchorOrigin={{ vertical: "top", horizontal: "left" }}
										badgeContent={
											<SmallAvatar4 alt="Remy Sharp" src={happyClients[1]} sx={{ border: isActive === 1 ? "2px solid #aa5eed" : '2px solid white' }} />
										}
									>
										<Badge
											onClick={(e) => changeCircleImage(e, 2)}
											overlap="circular"
											anchorOrigin={{ vertical: "top", horizontal: "right" }}
											badgeContent={
												<SmallAvatar3 alt="Remy Sharp" src={happyClients[6]} sx={{ border: isActive === 2 ? "5px solid #aa5eed" : '5px solid white' }} />
											}
										>
											<Avatar
												//onClick={(e) => changeCircleImage(e,6)}
												alt="Remy Sharp"
												src={happyClients[2]}
												sx={{ width: 420, height: 420, border: `10px solid #c18cfb` }}
												className={classes.bigCircle}
											/>
											{regAlert ? <div className={classes.imageTextAlert}>	{regAlertCard()}</div> : ""}
											<Typography variant="h6" className={classes.imageTextMain}>{currentUserData.name}</Typography>
											<Typography variant="p" className={classes.imageTextSub}>{t("lookingFor2")} {t(`${currentUserData.lookingFor}`)} </Typography>
											<div className={classes.thumbsUp} onClick={(e) => changeCircleImage(e, 8)}>
												<img src="/assets/images/ico-like.png" alt='' style={{ width: '35px', height: '35px', }} />
											</div>
											<div className={classes.Heart} onClick={(e) => changeCircleImage(e, 7)}>
												<img src="/assets/images/ico-heart.png" alt='' style={{ width: '35px', height: '35px', }} />
											</div>
											<div className={classes.topAvatar}>
												<Avatar
													alt="Remy Sharp"
													src={happyClients[0]}
													sx={{ width: 50, height: 50, border: isActive === 0 ? "2px solid #aa5eed" : '2px solid white' }}
													onClick={(e) => changeCircleImage(e, 0)}
												/>
											</div>
											<div className={classes.leftAvatar}>
												<Avatar
													alt="Remy Sharp"
													src={happyClients[3]}
													sx={{ width: 50, height: 50, border: isActive === 3 ? "2px solid #aa5eed" : '2px solid white' }}
													onClick={(e) => changeCircleImage(e, 3)}
												/>
											</div>
										</Badge>
									</Badge>
								</Badge>
							</Badge>
							{/* </Badge>
							</Badge> */}
						</Grid>
					</Grid>

				</Grid>
			</Grid >
		)
	}
	const renderMobile = () => {
		return loadCardContent === 1 ? (<div className={classes.logo_container}>
			<div className={classes.logo_img} >
				<img src="assets/images/logo_black.png" style={{ width: '107px', height: '25px' }} />
			</div>
			<div className={classes.banner_container} >
				<div className={classes.banner_img_c}>

					<div className={classes.main_cir_bg}>
						<div className={classes.mobCircle}
							//onClick={(e) => changeCircleImage(e, 0)}
							/*style={{ background: `url("${happyClients[6]}")no-repeat `, }} */>
						<Avatar
							//onClick={(e) => changeCircleImage(e,6)}
							alt="Remy Sharp"
							src={happyClients[2]}
							sx={{ width: 230, height: 230, }}
							className={classes.bigCircle}
						/></div>

						<div className={classes.main_cir_text}>
							<h6 className={classes.main_cir_h6}>{currentUserData.name}</h6>
							<p className={classes.main_text_p}>{t("lookingFor2")} {t(`${currentUserData.lookingFor}`)} </p>
						</div>

					</div>
					<div className={classes.main_cir}>
						<div className={classes.mobCicletopRight}
							onClick={(e) => changeCircleImage(e, 1)}
							style={{
								border: isActive === 1 ? "3px solid #aa5eed" : "3px solid white",
								/*background: `url("${happyClients[2]}") no-repeat`,*/
							}} >
							<Avatar
								//onClick={(e) => changeCircleImage(e,6)}
								alt="Remy Sharp"
								src={happyClients[1]}
								sx={{ width: 31.75, height: 31.75, }}
								className={classes.bigCircle}
							/>
						</div>
					</div>

					<div className={classes.main_cir2} >
						<div className={classes.mobCiclebottomRight}
							onClick={(e) => changeCircleImage(e, 4)}
							style={{
								border: isActive === 4 ? "3px solid #aa5eed" : "3px solid white",
								/*background: `url("${happyClients[4]}")`,*/

							}} >
							<Avatar
								//onClick={(e) => changeCircleImage(e,6)}
								alt="Remy Sharp"
								src={happyClients[4]}
								sx={{ width: 55.09, height: 55.09, }}
								className={classes.bigCircle}
							/>
						</div>
					</div>
					<div className={classes.main_cir3}>
						<div className={classes.mobCiclebottomLeft}
							onClick={(e) => changeCircleImage(e, 5)}
							style={{
								border: isActive === 5 ? "3px solid #aa5eed" : "3px solid white",
								/*background: `url("${happyClients[5]}")`,*/
							}} >
							<Avatar
								//onClick={(e) => changeCircleImage(e,6)}
								alt="Remy Sharp"
								src={happyClients[5]}
								sx={{ width: 36.41, height: 36.41, }}
								className={classes.bigCircle}
							/>
						</div>
					</div>
					<div className={classes.main_cir4}>
						<div className={classes.mobCicletopLeft}
							onClick={(e) => changeCircleImage(e, 2)}
							style={{
								border: isActive === 2 ? "3px solid #aa5eed" : "3px solid white",
								/*background: `url("${happyClients[1]}")`,*/

							}} >
							<Avatar
								//onClick={(e) => changeCircleImage(e,6)}
								alt="Remy Sharp"
								src={happyClients[6]}
								sx={{ width: 59.09, height: 59.09, }}
								className={classes.bigCircle}
							/>
						</div>
					</div>
					<div className={classes.main_cir5}>
						<div className={classes.mobCiclethumb}
							//onClick={(e) => changeCircleImage(e, 7)}
							style={{
								border: isActive === 7 ? "3px solid #aa5eed" : "3px solid white",
								background: `url("${'assets/images/ico-like.png'}")`,
								backgroundRepeat: 'no-repeat',
								backgroundSize: 'contain',
							}} />
					</div>
					<div className={classes.main_cir6}>
						<div className={classes.mobCicleheart}
							//onClick={(e) => changeCircleImage(e, 8)}
							style={{
								border: isActive === 8 ? "3px solid #aa5eed" : "3px solid white",
								background: `url("${'assets/images/ico-heart.png'}")`,
								backgroundPosition: 'top',
								backgroundSize: 'contain',
							}} />
					</div>
				</div>
			</div>
			<div className={classes.corner_1}>
				<div className={classes.mobCornerTop}
					onClick={(e) => changeCircleImage(e, 0)}
					style={{
						border: isActive === 0 ? "3px solid #aa5eed" : "3px solid white",
						/*background: `url("${happyClients[0]}") no-repeat`,*/

					}} >
					<Avatar
						//onClick={(e) => changeCircleImage(e,6)}
						alt="Remy Sharp"
						src={happyClients[0]}
						sx={{ width: 42, height: 42, }}
						className={classes.bigCircle}
					/>
				</div>
			</div>
			<div className={classes.corner_2}>
				<div className={classes.mobCornerbottomLeft}
					onClick={(e) => changeCircleImage(e, 3)}
					style={{
						border: isActive === 3 ? "3px solid #aa5eed" : "3px solid white",
						/*background: `url("${happyClients[3]}")no-repeat`,*/
					}} >
					<Avatar
						//onClick={(e) => changeCircleImage(e,6)}
						alt="Remy Sharp"
						src={happyClients[3]}
						sx={{ width: 27.55, height: 27.55, }}
						className={classes.bigCircle}
					/>
				</div>
			</div>
			{/* <div class="clearfix">	{regAlert ? regAlertCard() : ""}</div> */}

			<div className={classes.button_box}>
				<div className={classes.faceb_login}>
					<FacebookLogin
						appId="699443281105756"
						autoLoad={false}
						callback={responseFacebook}
						render={(renderProps) => (
							<img
								src="/assets/images/facebook_signup.png"
								alt="facebook" style={{ width: '100%' }}
								className={classes.instagram}
								onClick={renderProps.onClick}
							/>
						)}
					/>
				</div>
				<div className={classes.twit_login}>
					<img src="/assets/images/twitter_signup.png" alt="twitter" style={{ width: '100%' }} onClick={() => authHandler(twitterProvider)} />
				</div>
				<div className={classes.goog_login}>
					<GoogleLogin
						clientId="564348909029-f0062k8qg8fe5u20lplb2dhguch636fu.apps.googleusercontent.com"
						render={(renderProps) => (
							<img
								src="/assets/images/google_signup.png"
								alt="google" style={{ width: '100%' }}
								className={classes.instagram}
								onClick={renderProps.onClick}
							/>
						)}
					/>
				</div>
			</div>
			<div className={classes.bord_or}>
				<img src="assets/images/bord-or.png" style={{ width: '288px' }} />
			</div>
			<div className={classes.join_n} onClick={() => setCardContentType(2)}>
				<img src="assets/images/join2.png" />
			</div>

		</div>)
			: (<div className={classes.logo_container}>
				<div className={classes.logo_img} >
					<img src="assets/images/logo_white.png" style={{ width: '107px', height: '25px' }} />

				</div>{renderCardContents(loadCardContent)}
			</div>)
	}
	const renderTablet = () => {
		return (
			<Grid container>
				<Grid item xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
					<Card className={classes.tabCardSection}>
						{renderCardContents(loadCardContent)}
					</Card>
				</Grid>
				<Grid item xs={7} >
					<Grid container >
						{/* <Grid item xs={12}>{regAlert ? regAlertCard() : ""}</Grid> */}
						{/* <Grid item xs={12} lg={12} md={12} sm={12} display='flex' justifyContent={'end'} >
							<Avatar
								alt="Remy Sharp"
								src={happyClients[0]}
								sx={{ width: 50, height: 50, border: isActive === 0 ? "2px solid #aa5eed" : '2px solid white' }}
								onClick={(e) => changeCircleImage(e, 0)}
							/>
						</Grid> */}
						{/* <Grid item xs={1} lg={1} md={1} display='flex' justifyContent={'end'} alignItems='center'>
							<Avatar
								alt="Remy Sharp"
								src={happyClients[3]}
								sx={{ width: 50, height: 50, border: isActive === 3 ? "2px solid #aa5eed" : '2px solid white' }}
								onClick={(e) => changeCircleImage(e, 3)}
							/>
						</Grid> */}
						<Grid item xs={11} lg={11} md={11} sm={11} display='flex' justifyContent={'center'} sx={{ mb: 7 }}>
							<Badge
								onClick={(e) => changeCircleImage(e, 5)}
								overlap="circular"
								anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
								badgeContent={
									<SmallAvatar alt="Remy Sharp" src={happyClients[5]} sx={{ marginBottom: '140px', marginLeft: '75px', border: isActive === 5 ? "2px solid #aa5eed" : '2px solid white' }} />
								}
							>
								{/* <Badge
									onClick={(e) => changeCircleImage(e, 7)}
									overlap="circular"
									anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
									badgeContent={
										<SmallAvatarHeart alt="Remy Sharp" src="/assets/icons/landing_heart.svg" sx={{ marginTop: '60px', marginRight: '100px' }} />
									}
								> */}
								<Badge
									onClick={(e) => changeCircleImage(e, 4)}
									overlap="circular"
									anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
									badgeContent={
										<SmallAvatarTab alt="Remy Sharp" src={happyClients[4]} sx={{ marginBottom: '50px', marginRight: '30px', border: isActive === 5 ? "2px solid #aa5eed" : '2px solid white' }} />
									}
								>
									{/* <Badge
											onClick={(e) => changeCircleImage(e, 8)}
											overlap="circular"
											anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
											badgeContent={
												<SmallAvatarClose alt="Remy Sharp" src="/assets/icons/landing_thumbsup.svg" sx={{ marginTop: '60px', marginLeft: '140px' }} />
											}
										> */}
									<Badge
										onClick={(e) => changeCircleImage(e, 1)}
										overlap="circular"
										anchorOrigin={{ vertical: "top", horizontal: "left" }}
										badgeContent={
											<SmallAvatar4 alt="Remy Sharp" src={happyClients[1]} sx={{ border: isActive === 1 ? "2px solid #aa5eed" : '2px solid white' }} />
										}
									>
										<Badge
											onClick={(e) => changeCircleImage(e, 2)}
											overlap="circular"
											anchorOrigin={{ vertical: "top", horizontal: "right" }}
											badgeContent={
												<SmallAvatar3 alt="Remy Sharp" src={happyClients[6]} sx={{ border: isActive === 2 ? "5px solid #aa5eed" : '5px solid white' }} />
											}
										>
											<div className='nameContainer' onClick={() => setshowRegAlert(!regAlert)}>
												<Avatar
													alt="Remy Sharp"
													src={happyClients[2]}
													sx={{ width: 245, height: 245, border: `10px solid #c18cfb` }}
													className={classes.bigCircle}
												/>
												<Typography variant="h6" className={classes.imageTextMain}>{currentUserData.name}</Typography>
												<Typography variant="p" className={classes.imageTextSub}>{t("lookingFor2")} {t(`${currentUserData.lookingFor}`)} </Typography>
												<div className={classes.thumbsUp} onClick={(e) => changeCircleImage(e, 8)}>
													<img src="/assets/images/ico-like.png" alt='' style={{ width: '35px', height: '35px', }} />
												</div>
												<div className={classes.Heart} onClick={(e) => changeCircleImage(e, 7)}>
													<img src="/assets/images/ico-heart.png" alt='' style={{ width: '35px', height: '35px', }} />
												</div>
												<div className={classes.topAvatar}>
													<Avatar
														alt="Remy Sharp"
														src={happyClients[0]}
														sx={{ width: 50, height: 50, border: isActive === 0 ? "2px solid #aa5eed" : '2px solid white' }}
														onClick={(e) => changeCircleImage(e, 0)}
													/>
												</div>
												<div className={classes.leftAvatar}>
													<Avatar
														alt="Remy Sharp"
														src={happyClients[3]}
														sx={{ width: 50, height: 50, border: isActive === 3 ? "2px solid #aa5eed" : '2px solid white' }}
														onClick={(e) => changeCircleImage(e, 3)}
													/>
												</div>
											</div>
										</Badge>
									</Badge>
								</Badge>
							</Badge>
							{/* </Badge>
							</Badge> */}
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		)
	}

	const renderScreens = () => {
		if (window.innerWidth < 768) {
			return <div className={classes.bannerBgImageMob}>{renderMobile()}</div>
		} else if (window.innerWidth >= 768 && window.innerWidth <= 1280) {
			return <div className={classes.bannerBgImageTab}>{renderTablet()}	</div>
		} else if (window.innerWidth > 1280) {
			return <div className={classes.bannerBgImage}>{renderWeb()}	</div>
		}
	}
	return (
		<>
			{
				renderScreens()
			}
		</>
	);
};
const mapStateToProps = (state) => {
	return {
		userToken: state.LoginReducer.userToken,
		happyClientsData: state.HappyClientsReducer.happyClientsData,
		is_username_exist: state.RegisterReducer.is_username_exist,
		registerStatus: state.RegisterReducer.data,
		loginStatus: state.LoginReducer.loginMessage,
		emailStatus: state.RegisterReducer.emailStatus,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getHappyClientsService: () =>
			dispatch(happyClientsAction.getHappyClientsService()),
		verifyUniqueUser: (data) => dispatch(registerAction.verifyUniqueUser(data)),
		uniqueStatusNull: () => dispatch(registerAction.uniqueStatusNull()),
		registerService: (requestData) =>
			dispatch(registerAction.registerService(requestData)),
		loginService: (requestData) =>
			dispatch(loginAction.loginService(requestData)),
		socialLoginService: (requestData) =>
			dispatch(loginAction.socialLoginService(requestData)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Banner);

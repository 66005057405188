import React, { useEffect, useState } from 'react'
import Pusher from 'pusher-js';
import {
	Button,
	Dialog,
	DialogContent,
	DialogContentText,
	Typography,
	Card,
	CardHeader,
	CardContent,
	Divider
} from '@mui/material';
import { ReactComponent as BackgroundIcon } from '../components/icons/bg claim rewards.svg';
import { ReactComponent as ThumbsUpIcon } from '../components/icons/ico-like points.svg';
import { ReactComponent as CloseIcon } from '../components/icons/ico-close.svg';
import { useHistory } from 'react-router';
import { ReactComponent as TrophyIcon } from '../components/icons/trophy-cup.svg';
import { ReactComponent as MessageIcon } from '../components/icons/ico-message notif.svg';
import { connect } from 'react-redux';
import * as userDetailsAction from '../redux/action/userDetailsAction';
import * as commonAction from '../redux/action/commonAction';
import useStyles from '../styles/notification';
import { useTranslation } from 'react-i18next';
import { getTimeStamp } from '../utils';
import moment from 'moment';
const Notify = (props) => {
	let history = useHistory();
	const classes = useStyles();
	const {t}=useTranslation()
	const [footerData, setFooterData] = useState([]);
	const [likePointAlert, setLikePointAlert] = useState(false);
	const [levelUpAlert, setLevelUpAlert] = useState(false);
	const [pushNotification, setNotification] = useState(false);
	useEffect(() => {
		if (footerData.user_id > 0&&props.subscribe) {
			const pusher = new Pusher('e99d25639dee44d98e2b', {
				cluster: 'mt1',
				encrypted: true,
				// authTransport: 'jsonp',
				authEndpoint: `${process.env.REACT_APP_CHAT_URL}GetToken_Pusher`,
				auth: {
					headers: {
						Authorization: `Bearer ${localStorage.getItem('userToken')}`,
						'content-type': 'application/javascript',
						'Access-Control-Allow-Origin': '*',
					},
				},
			});
			const channel = pusher.subscribe(
				`private-WebNotify.${footerData.user_id}`
			);
			channel.bind('App\\Events\\Web_notifications', (data) => {
				console.log("WEB NOTIFICATION", data.message);
				if (data.message.activity_type === 12) {
					setLikePointAlert(true)
					props.userGeneralDetailsService(localStorage.getItem('userToken'));
				} else if (data.message.activity_type === 9) {
					setLevelUpAlert(true)
					props.userGeneralDetailsService(localStorage.getItem('userToken'));
				} else if (data.message.activity_type === 13) {
					setNotification(true)
					setTimeout(() => {
						setNotification(false)
					}, 5000);
					props.getFooterData(localStorage.getItem('userToken'));

				}
				// if (Object.keys(data).length > 0) {
				//   listenNewMessage(localStorage.getItem('currentChatId'));
				// }
			});
		}
	}, [footerData,props.subscribe]);


	const listenNewMessage = (details) => {

	};
	useEffect(() => {
		//   if (localStorage.getItem('userToken') === null) {
		// 	history.push('/');
		//   } else {
		if (localStorage.getItem('userLevelData') != null) {
			let data = localStorage.getItem('userLevelData');
			let datas = JSON.parse(data);
			setFooterData(datas);
		}

		//   }
	}, [localStorage.getItem('userToken')]);
	const closeAlert = () => {
		setLikePointAlert(false)
		setLevelUpAlert(false)
	}
	return (
		<>
			{pushNotification===true ? <Card sx={{  }} className={classes.card}>
				<CardHeader
				sx={{pb:1}}
					avatar={
						<MessageIcon/>
					}
					action={<Typography variant="p">{getTimeStamp(moment().utc().format('MMMM Do YYYY h:mm:ss a'))}</Typography>}
					title={`Message`}
					subheader=""
					classes={{
						title: classes.title,
						action: classes.notificationTimeInfo,
						avatar:classes.avatar
					}}
				/>
				<Divider className={classes.cardHeader}/>
				<CardContent 	sx={{paddingTop:'15px !important',paddingBottom:'15px !important'}}>
					<Typography variant="p" className={classes.message}>{t("youHaveNewMwssage")}</Typography>
				</CardContent>
			</Card> : ''}
			<Dialog open={likePointAlert || levelUpAlert}>
				<BackgroundIcon style={{ position: 'relative' }} />
				<CloseIcon style={{ position: 'absolute', top: '5%', left: '90%', cursor: 'pointer' }} onClick={() => closeAlert()} />
				<DialogContentText
					sx={{ position: 'absolute', top: '15%', left: '35%' }}
				>
					<Typography sx={{ fontWeight: 'bold' }}>{t("likePointPusherAlertCongratulation")}</Typography>
				</DialogContentText>
				{likePointAlert ? <ThumbsUpIcon
					style={{ position: 'absolute', top: '35%', left: '43%' }}
				/> : ''}
				{levelUpAlert ? <TrophyIcon
					style={{ position: 'absolute', top: '35%', left: '45%' }}
				/> : ''}
				<DialogContent sx={{ p: 0 }}>
					<DialogContentText
						sx={levelUpAlert ? { position: 'absolute', top: '60%', left: '22%', margin: '0px 5% ' } : { position: 'absolute', top: '60%', left: '30px', marginLeft:'70px', marginRight:'70px',fontSize:'13px',display:'flex',justifyContent:'center' }}
					>
						<Typography variant="p">
							{likePointAlert ? t("receive5LikePoint") : ''}
							{levelUpAlert ?t("yourLevelIsUp") : ''}
						</Typography>
					</DialogContentText>
					<DialogContentText
						sx={{ position: 'absolute', top: '72%', left: '10%',fontSize:'13px' }}
					>
						{/* {likePointAlert ? <Typography variant="p">
							{t("loginWith24HourForMore")}
						</Typography> : ''} */}
						<Button
							primary
							variant="contained"
							sx={{
								borderRadius: '10px',
								margin: '20px 25px ',
								position: 'absolute',
								top: '87%',
								left: '5%',
								width: '270px',
							}}
							type="submit"
							onClick={() => closeAlert()}
						>
						{t("claim")}
						</Button>
					</DialogContentText>
				</DialogContent>
				{/* </div> */}
			</Dialog>
		</>
	)
}
const mapStateToProps = (state) => {
	return {
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		userGeneralDetailsService: (requestData) =>
			dispatch(userDetailsAction.userGeneralDetailsService(requestData)),
		getFooterData: (token) => dispatch(commonAction.getFooterData(token)),
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(Notify)

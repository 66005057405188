import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import UserLayout from '../layouts/userLayout';
import {
  Grid,
  Card,
  Typography,
  InputAdornment,
  ListItemAvatar,
  ListItem,
  List,
  Avatar,
  ListItemText,
  TextField,
  styled,
  Badge,
  Button,
  Divider,
  CircularProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Popper,
  Fade,
  ListItemIcon,
  Box,
  ListItemButton
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { ReactComponent as AudioIcon } from '../components/icons/ico_audio.svg';
import { ReactComponent as GalleryIcon } from '../components/icons/ico_gallery.svg';
import { ReactComponent as StickerIcon } from '../components/icons/ico_sticker.svg';
import { ReactComponent as FavoriteIcon } from '../components/icons/ico_heart_active.svg';
import { ReactComponent as SmileyIcon } from '../components/icons/smiley green.svg';
import { ReactComponent as ExpandCollapseIcon } from '../components/icons/arrow_expand_collapse.svg';
import { ReactComponent as ExpandIcon } from '../components/icons/Expand-Ico.svg';
import { ReactComponent as FavoriteIconInactive } from '../components/icons/ico heart inactive.svg';
import { ReactComponent as LuckyShotIcon } from '../components/icons/ico-lucky shot.svg';
import { ReactComponent as CloseIcon } from '../components/icons/ico-close.svg';
import { ReactComponent as RecentShowIcon } from '../components/icons/recent-show.svg';
import { ReactComponent as RecentHideIcon } from '../components/icons/recent-hide.svg';
import {ReactComponent as Trash} from "../components/icons/tr_new.svg";
import useStyles from '../styles/chat';
import { useHistory } from 'react-router';
import * as chatAction from '../redux/action/chatAction';
import Pusher from 'pusher-js';
import * as galleryAction from '../redux/action/galleryAction';
import * as commonAction from '../redux/action/commonAction';
import * as lastVisitorAction from '../redux/action/lastVisitorAction';
import * as headerAction from '../redux/action/headerAction';
import Recorder from '../utils/Recorder';
import ChatStickers from '../utils/chatStickers';
import VoicePlay from '../utils/voicePlayer';
import Alert from '../components/common/alert';
import Loader from '../utils/loader';
import SendIcon from '@mui/icons-material/Send';
import ConfirmationAlert from '../components/common/confirmationAlert';
import { getIsMobile, getTimeStamp } from '../utils';
import ReactCardFlip from 'react-card-flip';
import MicIcon from '@mui/icons-material/Mic';
import { useTranslation } from 'react-i18next';
import OtpVerifyAlert from '../utils/otpVerifyAlert';
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    bottom: '18%',
    right: '18%',
  },
}));


const Chat = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const isMobile = getIsMobile();
  const [chatHistory, setChatHistory] = useState([]);
  const [text, setText] = useState('');
  const [footerData, setFooterData] = useState([]);
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const [dynamicUserDetails, setDynamicUserDetails] = useState({});
  const [recentMessages, setRecentMessages] = useState([]);
  const [files, setFile] = useState([]);
  const [uploadModal, setUploadModal] = useState(false);
  const [totalStickerList, setTotalStickerList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stickerMenu, setStickerMenu] = useState(false);
  const [placement, setPlacement] = useState();
  const [recordingStatus, setRecordinStatus] = useState(false);
  const [recordDuration, setRecordDuration] = useState('');
  const [expand, setExpand] = useState(false);
  const [commonAlertModal, setCommonAlertModal] = useState(false);
  const [commonAlertMessage, setCommonAlertMessage] = useState('');
  const [commonAlertButtonText, setCommonAlertButtonText] = useState('');
  const [IconName, setIconName] = useState('');
  const [commonTitle, setCommonAlertTitle] = useState('');
  const [containLink, setContainLink] = useState(false);
  const [navigationLink, setNavigateLink] = useState('');
  const [closeIcon, setCloseIcon] = useState(false);
  const [messageId, setMessageId] = useState('');
  const [meassageLoader, setMessageLoader] = useState(false);
  const [initialLoader, setInitialLoader] = useState(false);
  const [newestMemberAlert, setNeweastMemberAlert] = useState(false);
  const [alertConfirmButton, setAlertConfirmButton] = useState(false);
  const [alertCloseButton, setAlertCloseButton] = useState(false);
  const [alertConfirmButtonText, setAlertConfirmButtonText] = useState('');
  const [alertCloseButtonText, setAlertCloseButtonText] = useState('');
  const [alertText, setAlertText] = useState('');
  const [alertTitle, setAlertTitle] = useState('');
  const [galleryImageId, setGalleryImageId] = useState('');
  const [isFlipped, setIsFliped] = useState(false);
  const chatContainer = useRef();
  const [currentUnreadCount, setCurrentUnreadCount] = useState(null);
  const [emoji, setEmoji] = useState(false)
  const [newChatAdded, setNewChatAdded] = useState(false)
  const [luckyChatAdded, setLuckyChatAdded] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [noOldMessages, setNoOldMessages] = useState(false)
  const [recentEmojisVisibility, setRecentEmojisVisibility] = useState(false)
  const [recentEmojiesList, setRecentEmojiList] = useState([]);
  const [accVerifiedStatus,setAccVerifyStatus]=useState(false);
  const [msgStickerId,setMsgStickerId]=useState(0)
  useEffect(() => {

    if (footerData.user_id > 0) {
      const pusher = new Pusher('e99d25639dee44d98e2b', {
        cluster: 'mt1',
        encrypted: true,
        // authTransport: 'jsonp',
        authEndpoint: `${process.env.REACT_APP_CHAT_URL}GetToken_Pusher`,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'content-type': 'application/javascript',
            'Access-Control-Allow-Origin': '*',
          },
        },
      });
      const channel = pusher.subscribe(
        `private-ChatMessage.${footerData.user_id}`
      );

      channel.bind('App\\Events\\MessageSent', (data) => {
        console.log(data);
        if (Object.keys(data).length > 0) {
          listenNewMessage(data, localStorage.getItem('currentChatId'));
        }
      });
    }
  }, [footerData]);

  const listenNewMessage = (messageData, details) => {
    let profileMatched = false;
    if (window.location.pathname === '/chat') {
      if (messageData.to_user && messageData.to_user === parseInt(localStorage.getItem('currentChatId'))) {
        setNewChatAdded(true)
        profileMatched = true;
        let data = new FormData();
        data.append('to_user_id', details);
        props.getLatestChatData(data, localStorage.getItem('userToken'));
      } else {
        for (var i = 0; i < props.chatHistory.length; i++) {
          if (chatHistory[i].user_id === messageData.to_user) {
            setNewChatAdded(true)
            props.getChatHistoryData(localStorage.getItem('userToken'));
            profileMatched = true;
            break;
          }
        }
      }

    }

    if (!profileMatched) {
      props.getChatHistoryData(localStorage.getItem('userToken'));
      setNewChatAdded(true)
    }
  };

  const sendMessage = (stickerId) => {
    if(localStorage.getItem('AccVerifiedStatus')==='1'){
      setEmoji(false);
      setUploadModal(false);
      setRecordinStatus(false)
      setStickerMenu(false)
      let data = new FormData();
      data.append('to_user_id', dynamicUserDetails.user_id);
      if (text.length > 0) {
        data.append('content', text);
        for (var i = 0; i < chatHistory.length; i++) {
          if (chatHistory[i].user_id === parseInt(localStorage.getItem('currentChatId'))) {
            chatHistory[i].last_message = text;
          }
        }
      }
      if (stickerId > 0) {
        data.append('sticker_id', stickerId);
        for (var i = 0; i < chatHistory.length; i++) {
          if (chatHistory[i].user_id === parseInt(localStorage.getItem('currentChatId'))) {
            chatHistory[i].last_message = 'You Sent a Sticker';
          }
        }
      }

      if (files.length > 0) {
        files.forEach(function (item, index) {
          data.append('image', item);
        });
      }

      if (text.length > 0 || files.length > 0 || stickerId > 0) {
        props.PostNewMessage(data, localStorage.getItem('userToken'));
      }
    }else{
      setAccVerifyStatus(true)
      setMsgStickerId(stickerId)
    }

  };

const removeVerifyAlert=() => {
         if(text.length > 0 || files.length > 0 || msgStickerId > 0){
          setAccVerifyStatus(false)
          sendMessage(msgStickerId)
        }
}

  const sendPicConfirm = (item) => {
    setGalleryImageId(item.gallery_id);
    setNeweastMemberAlert(true);
    setAlertConfirmButton(true);
    setAlertCloseButton(true);
    setAlertConfirmButtonText(t("gallerySend"));
    setAlertCloseButtonText(t("claim"));
    setAlertTitle(t("sendPicture"));
    setAlertText(t("sendPicContent"));
  };

  useEffect(() => {
    if (props.postMessageStatus !== 'Emoji Not Found. Kindly Select Emoji in the Emoji List' &&
      props.postMessageStatus !== 'Kindly Buy the Emoji and Use it.' && props.postMessageStatus !== null) {
      CloseAlert();
      setText('');
      setUploadModal(false);
      setFile([]);
      setRecordinStatus(false);
      setRecentEmojisVisibility(false)
      let data = new FormData();
      data.append('to_user_id', dynamicUserDetails.user_id);
      props.getLatestChatData(data, localStorage.getItem('userToken'));
      props.getRecentEmojies(localStorage.getItem('userToken'))
      props.getHeaderData(localStorage.getItem('userToken'))
    } else if ((props.postMessageStatus === 'Emoji Not Found. Kindly Select Emoji in the Emoji List' ||
      props.postMessageStatus === 'Kindly Buy the Emoji and Use it.') && props.postMessageStatus !== null) {
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("message"));
      setAlertText(props.postMessageStatus);
      props.setNewChatStatusNull();
    }
  }, [props.postMessageStatus]);

  const getCurrentChatDetails = (item) => {
    if (isMobile || window.innerWidth <= 1280) {
      setIsFliped(true);
    }
    if (item.user_id != currentUserDetails.user_id) {
      setMessageLoader(true);
      setRecentMessages([]);
      localStorage.setItem('currentChatId', item.user_id);
      setCurrentUserDetails(item);
      setText('');
      setCurrentUnreadCount(item.unread_count);
      setNoOldMessages(false)
    }
    let data = new FormData();
    data.append('to_user_id', item.user_id);
    props.ReadNewMessage(data, localStorage.getItem('userToken'));
    // setTimeout(() => {
    //   setMessageLoader(false)
    // }, 3000);
  };
  useEffect(() => {
    if (props.messageCount) {
      for (var i = 0; i < chatHistory.length; i++) {
        if (props.messageCount.user_id == chatHistory[i].user_id) {
          chatHistory[i].unread_count = props.messageCount.unread_count;
          props.setNewChatStatusNull()
          break;

        }
      }
    }
  }, [props.messageCount]);
  useEffect(() => {
    if (currentUserDetails.user_id != undefined) {
      setDynamicUserDetails(currentUserDetails);
      let data = new FormData();
      data.append('to_user_id', currentUserDetails.user_id);
      props.getLatestChatData(data, localStorage.getItem('userToken'));
    }
  }, [currentUserDetails]);
  useEffect(() => {
    if (props.recentMessages.length > 0) {
      setMessageLoader(false);
      setRecentMessages(props.recentMessages);
      setStickerMenu(false);
      let data = new FormData();
      data.append('to_user_id', props.recentMessages[0].user_id);
      props.ReadNewMessage(data, localStorage.getItem('userToken'));
      for (var i = 0; i < chatHistory.length; i++) {
        if (chatHistory[i].user_id === props.recentMessages[props.recentMessages.length - 1].user_id) {
          chatHistory[i].last_message = props.recentMessages[props.recentMessages.length - 1].content;
        }
      }
      setMessageId(
        props.recentMessages[props.recentMessages.length - 1].message_id
      );
    }
  }, [props.recentMessages]);

  const handleTextChange = (e) => {
    // if (emoji) {
    //   setEmoji(false)
    // }
    setUploadModal(false);
    setRecordinStatus(false)
    setStickerMenu(false)
    if (e.keyCode === 13) {
      e.preventDefault();
      if (props.nextMessageStatus) {
        sendMessage();
      } else {
        setCommonAlertModal(true)
      }

    } else {
      setText(e.target.value);
    }
  };

  const addToText = (emoji) => {
    let textwithEmoji = text.concat(emojis(emoji))
    setText(textwithEmoji);
  }
  const onEmojiClick = (event, emojiObject) => {
    let textwithEmoji = text.concat(emojiObject.emoji)
    setText(textwithEmoji);
  };
  useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      history.push('/');
    } else {
      let data = localStorage.getItem('userLevelData');
      let datas = JSON.parse(data);
      setFooterData(datas);
    }
  }, [localStorage.getItem('userToken')]);

  useEffect(() => {
    setInitialLoader(true);
    // if (props.chatHistory.length === 0) {
    //   setTimeout(() => {
    //     props.getChatHistoryData(localStorage.getItem('userToken'));
    //   }, 2000);
    // }
    props.getChatHistoryData(localStorage.getItem('userToken'));
    props.getGalleryPhotoList(localStorage.getItem('userToken'));
    props.getRecentEmojies(localStorage.getItem('userToken'))
    setTimeout(() => {
      setInitialLoader(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (luckyChatAdded && props.chatHistory.length > 0) {
      for (var i = 0; i < props.chatHistory.length; i++) {
        if (props.chatHistory[i].is_lucky === 1) {
          localStorage.setItem('currentChatId', props.chatHistory[i].user_id);
          setChatHistory(props.chatHistory);
          setCurrentUserDetails(props.chatHistory[i]);
        }
      }
    } else if (props.chatHistory.length > 0 && newChatAdded === false) {
      if (localStorage.getItem('currentChatId') !== null) {
        for (var i = 0; i < props.chatHistory.length; i++) {
          if (props.chatHistory[i].user_id === parseInt(localStorage.getItem('currentChatId'))) {
            setChatHistory(props.chatHistory);
            setCurrentUserDetails(props.chatHistory[i]);
            break;
          } else if (i === props.chatHistory.length - 1) {
            setChatHistory(props.chatHistory);
            setCurrentUserDetails(props.chatHistory[0]);
            localStorage.setItem('currentChatId', props.chatHistory[0].user_id);
          }
        }
      } else {
        localStorage.setItem('currentChatId', props.chatHistory[0].user_id);
        setChatHistory(props.chatHistory);
        setCurrentUserDetails(props.chatHistory[0]);
      }
    } else if (props.chatHistory.length > 0) {
      setChatHistory(props.chatHistory);
    }
  }, [props.chatHistory]);

  useEffect(() => {
    if (props.recentEmojiesList.length > 0) {
      setRecentEmojiList(props.recentEmojiesList)
    }
  }, [props.recentEmojiesList])

  const handleAudioStartstop = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
    if (recordingStatus || event.currentTarget === undefined) {
      setRecordDuration(props.recordDuration);
      setRecordinStatus(false);
    } else if (!recordingStatus && props.recordDuration > 0) {
      setRecordDuration(props.recordDuration);
      setRecordinStatus(true);
    }
    setStickerMenu(false);
    setEmoji(false)
    setUploadModal(false);
    setRecentEmojisVisibility(false)

  };
  const showHideRecentEmojies = () => {
    setRecentEmojisVisibility(true)
    setStickerMenu(false);
    setEmoji(false)
    setUploadModal(false);
    setRecordinStatus(false);
  }
  const handleEmojiSelect = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setPlacement(newPlacement);
    setEmoji(!emoji)
    setStickerMenu(false);
    setRecordinStatus(false);
    setUploadModal(false);
    setRecentEmojisVisibility(false)
  };
  const handleImageSelect = (newPlacement) => (event) => {
    setRecentEmojisVisibility(false)
    setRecordinStatus(false);
    setStickerMenu(false);
    setEmoji(false)
    setAnchorEl(event.currentTarget);
    setUploadModal(!uploadModal);
    setPlacement(newPlacement);
  };


  useEffect(() => {
    if (props.stickerList.length > 0) {
      setTotalStickerList(props.stickerList);
    }
  }, [props.stickerList]);

  const handleClick = (newPlacement) => (event) => {
    setRecordinStatus(false);
    setEmoji(false)
    setUploadModal(false)
    setRecentEmojisVisibility(false)
    setAnchorEl(event.currentTarget);
    setStickerMenu((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const scrollToMyRef = () => {
    if (recentMessages.length > 0) {


      const scroll =
        chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
      chatContainer.current.scrollTo(0, scroll);
    }
  };

  useEffect(() => {
    scrollToMyRef();
  }, [recentMessages]);
  const setCommonModalFalse = () => {
    setCommonAlertModal(false);
    setCommonAlertMessage('');
    setCommonAlertButtonText('');
    setIconName('');
    setCommonAlertTitle('');
    setContainLink(false);
    setNavigateLink('');
    setCloseIcon(false);
  };
  const CloseAlert = () => {
    setNeweastMemberAlert(false);
    setAlertConfirmButton(false);
    setAlertCloseButton(false);
    setAlertConfirmButtonText('');
    setAlertCloseButtonText('');
    setAlertTitle('');
    setAlertText('');
  };
  const sendGalleryPic = () => {
    CloseAlert();
    setUploadModal(false);
    let data = new FormData();
    data.append('to_user_id', dynamicUserDetails.user_id);

    if (galleryImageId != '') {
      data.append('image', galleryImageId);
    }
    if (galleryImageId != '') {
      props.PostNewMessage(data, localStorage.getItem('userToken'));
    }
  };
  const luckyShot = () => {
    setInitialLoader(true);
    setTimeout(() => {
      setInitialLoader(false);
    }, 3000);
    props.LuckyChatService(localStorage.getItem('userToken'));
  };

  useEffect(() => {
    if (props.luckyChatStatus != null) {
      setLuckyChatAdded(true)
      setInitialLoader(false);
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(t("congratulations"));
      setAlertText(t("gotNewMember"));
      props.getChatHistoryData(localStorage.getItem('userToken'));
      props.setNewChatStatusNull();
    } else if (props.luckyChatStatus === false) {
      setInitialLoader(false);
      setNeweastMemberAlert(true);
      setAlertConfirmButton(false);
      setAlertCloseButton(true);
      setAlertConfirmButtonText('');
      setAlertCloseButtonText(t("Close"));
      setAlertTitle(`Ohooo`);
      setAlertText(t("somethingWentWrongTryLater"));
      props.setNewChatStatusNull();
    }
  }, [props.luckyChatStatus]);
  const handleFavorite = (e, item) => {
    e.stopPropagation();
    let data = new FormData();
    data.append('member_id', item.user_id)
    data.append('action', item.is_favourite === 1 ? 0 : 1)
    props.addorRemoveFavorite(data)
  }
  useEffect(() => {
    if (props.favoriteStatus) {
      props.getChatHistoryData(localStorage.getItem('userToken'));
    }
  }, [props.favoriteStatus])

  const getUserData = (e, id) => {
    e.stopPropagation();
    localStorage.setItem('currentID', id);
    props.getUserByID(localStorage.getItem('userToken'), id);
    props.addLastVisit(id, localStorage.getItem('userToken'));
    history.push('/user-profile');
  };

  const onScroll = (e) => {
    const currentScrollY = e.target.scrollTop;
    if (currentScrollY === 0 && recentMessages.length > 0 && !noOldMessages) {
      const data = new FormData();
      data.append('old_message_id', recentMessages[0].message_id)
      data.append('to_user_id', currentUserDetails.user_id)
      props.getOldChatData(data, localStorage.getItem('userToken'))

    }
  }

  useEffect(() => {
    if (props.oldMessages === 'noMessages') {
      setNoOldMessages(true)
      props.setNewChatStatusNull();
    } else if (props.oldMessages.length > 0) {
      setNoOldMessages(false)
      var messages = [];
      messages = recentMessages;
      for (var i = props.oldMessages.length - 1; i >= 0; i--) {
        messages.splice(0, 0, props.oldMessages[i])
      }
      setRecentMessages(messages)
      props.setNewChatStatusNull();
    }
  }, [props.oldMessages])

  const handleDeleteButton = (e, item) => {
    e.stopPropagation();
    if (window.confirm(`Chat mit ${item.username} löschen?`)) {
      localStorage.setItem('currentChatId', '');
      let data = new FormData();
      data.append('to_user_id', item.user_id)
      props.deleteChat(data, localStorage.getItem('userToken'))
    }
  }

  useEffect(()=> {
    if (props.deleteChatEvent) {
      setInitialLoader(true);
     props.getChatHistoryData(localStorage.getItem('userToken'));
      props.getGalleryPhotoList(localStorage.getItem('userToken'));
      props.getRecentEmojies(localStorage.getItem('userToken'))

      setTimeout(() => {
        setInitialLoader(false);
      }, 3000);
    }
  }, [props.deleteChatEvent])

  const renderWeb = () => {
    return (
      <>
        <Card sx={{ minHeight: '500px', maxHeight: '800px' }}>
          <Grid
            container
            justifyContent="flex-start"
            sx={{ padding: '24px', paddingBottom: '0px', paddingRight: '0px' }}
          >
            <Grid item xs={4} sx={{ borderColor: '1px solid #80808038' }}>
              <Grid container display="flex">
                <Grid item xs={!expand ? 9 : 3}>
                  <Typography variant="body1" component="h6">
                    {t("messages")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={!expand ? 2 : 1}
                  display="flex"
                  justifyContent="end"
                  sx={{ mr: 1 }}
                >
                  {chatHistory.length > 0 && !expand ? (
                    <ExpandCollapseIcon
                      onClick={() => setExpand(!expand)}
                      style={{ cursor: 'pointer' }}
                    />
                  ) : chatHistory.length > 0 && expand ? (
                    <ExpandIcon
                      onClick={() => setExpand(!expand)}
                      style={{ cursor: 'pointer' }}
                    />) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid item xs={!expand ? 4 : 1.8}>
              <List
                sx={{ minHeight: '480px', maxHeight: '480px', overflow: 'auto', cursor: 'pointer' }}
              >
                {chatHistory.length > 0 ? (
                  chatHistory.map((item) => {
                    return (
                      <div key={item.user_id}>
                        <ListItem
                          key={item.user_id}
                          divider={true}
                          sx={{
                            padding: '10px 24px',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            borderBottom: '0px',
                          }}
                          onClick={() => getCurrentChatDetails(item)}
                        >
                          {item.is_favourite === 1 ? (
                              <div>
                            <FavoriteIcon className={classes.heartIcon} onClick={(e) => handleFavorite(e, item)} />
                                <div onClick={(e)=>handleDeleteButton(e, item)} className={classes.trashIcon}><Trash/></div>
                              </div>
                          ) : (
                              <div>
                            <FavoriteIconInactive
                              className={classes.heartIcon}
                              onClick={(e) => handleFavorite(e, item)} />
                                <div onClick={(e)=> handleDeleteButton(e, item)} className={classes.trashIcon}><Trash/></div>
                            </div>
                          )}
                          <ListItemAvatar>
                            {item.is_online === 1 ? (
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                variant="dot"
                              >
                                <Avatar
                                  alt="avatar"
                                  src={`${encodeURI(item.mobile_profile_image['75'])}`}
                                  onClick={(e) => getUserData(e, item.user_id)}
                                  sx={{
                                    height: '70px',
                                    width: '70px',
                                    marginRight: '10px',
                                    marginLeft: '10px',
                                    border: '2px solid #F7B573',
                                  }}
                                />
                              </StyledBadge>
                            ) : (
                              <Avatar
                                onClick={(e) => getUserData(e, item.user_id)}
                                alt="avatar"
                                src={`${encodeURI(item.mobile_profile_image['75'])}`}
                                sx={{
                                  height: '70px',
                                  width: '70px',
                                  marginRight: '10px',
                                  marginLeft: '10px',
                                  border: '2px solid #F7B573',
                                }}
                              />
                            )}
                          </ListItemAvatar>
                          {!expand ? (
                            <ListItemText
                              sx={{ maxWidth: '10rem', minWidth: '10rem' }}
                              primary={
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{ color: '#202020' }}
                                  >
                                    {item.username},&nbsp;{' '}
                                    {item.gender === 'Male'
                                      ? 'M'
                                      : item.gender === 'Female'
                                        ? 'W'
                                        : ''}
                                    &nbsp; {item.age === 0 ? '' : item.age}
                                  </Typography>
                                </Grid>
                              }
                              secondary={
                                <Grid
                                  container
                                  alignItems="center"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    component="p"
                                    variant="body2"
                                    className={classes.descmsg}
                                  >
                                    {item.last_message?.length > 15
                                      ? `${item.last_message?.slice(0, 15)}...`
                                      : item.last_message}
                                  </Typography>
                                </Grid>
                              }
                            />
                          ) : (
                            ''
                          )}
                          {item.unread_count > 0 ? (
                            <ListItemText
                              primary={
                                <Box sx={{
                                  bgcolor: '#2887c9',
                                  borderColor: '#2887c9',
                                  m: 1,
                                  border: 1,
                                  width: '1.7rem',
                                  height: '1.7rem',
                                  textAlign: 'center',
                                  borderRadius: '50%',
                                  color: '#fff',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'

                                }}>
                                  <Typography
                                    variant="body1"
                                    component="p"
                                    sx={{
                                      color: '#fff',
                                      textAlign: 'center'
                                    }}
                                  >
                                    {item.unread_count > 10 ? '10+' : item.unread_count}
                                  </Typography>
                                </Box>
                              }
                              secondary={
                                <Typography
                                  variant="body2"
                                  component="p"
                                  sx={{ color: 'black', textAlign: 'left', fontSize: '10px' }}
                                >
                                  {getTimeStamp(item.last_message_time)}
                                </Typography>
                              }
                            />
                          ) : (
                            ''
                          )}

                        </ListItem>
                        <Divider sx={{ marginLeft: '-24px' }} />
                      </div>
                    );
                  })
                ) : (
                  <Typography variant="body1" component="radialGradient">
                    {t("noMessages")}
                  </Typography>
                )}
              </List>
              <Grid
                itemxs={!expand ? 4 : 1.8}
                sx={{ mt: 1, cursor: 'pointer' }}
                onClick={() => luckyShot()}
              >
                <Grid
                  container
                  sx={{ display: 'flex', justifyContent: 'start' }}
                >
                  <Grid item xs={2} sx={{ maxWidth: '20% !important' }}>
                    <LuckyShotIcon />
                  </Grid>
                  {!expand ? (
                    <Grid item xs={9} sx={{ mt: 1 }}>
                      <Typography variant="h6" xs={12}>
                        {t("footerFlower")}
                      </Typography>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={!expand ? 8 : 10} >
              {chatHistory.length > 0 ? (
                <Grid>
                  <Grid item xs={12} sx={{ maxHeight: '100px' }}>
                    <ListItem sx={{ padding: '10px 24px' }}>
                      <ListItemAvatar>
                        {currentUserDetails.is_online === 1 ? (
                          <StyledBadge
                            overlap="circular"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                            variant="dot"
                          >
                            <Avatar
                              alt="avatar"
                              src={`${currentUserDetails.pictures}`}
                              sx={{
                                height: '70px',
                                width: '70px',
                                marginRight: '10px',
                                marginLeft: '10px',
                                border: '2px solid #F7B573',
                              }}
                            />
                          </StyledBadge>
                        ) : (
                          <Avatar
                            alt="avatar"
                            src={`${currentUserDetails.pictures}`}
                            sx={{
                              height: '70px',
                              width: '70px',
                              marginRight: '10px',
                              marginLeft: '10px',
                              border: '2px solid #F7B573',
                            }}
                          />
                        )}
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Typography
                              variant="body1"
                              component="h6"
                              sx={{ color: '#202020' }}
                            >
                              {currentUserDetails.username},&nbsp;
                              {currentUserDetails.gender === 'Male'
                                ? 'M'
                                : currentUserDetails.gender === 'Female'
                                  ? 'W'
                                  : ''}
                              &nbsp;{' '}
                              {currentUserDetails.age === 0
                                ? ''
                                : currentUserDetails.age}
                            </Typography>
                          </Grid>
                        }
                      // secondary={
                      //   <Grid
                      //     container
                      //     alignItems="center"
                      //     justifyContent="space-between"
                      //   >
                      //     <Typography
                      //       component="p"
                      //       variant="body2"
                      //       className={classes.descmsg}
                      //     >
                      //       Main Twitter Account: @JessicaCreed
                      //     </Typography>
                      //   </Grid>
                      // }
                      />
                      {currentUserDetails.is_favourite === 1 ? (
                        <FavoriteIcon className={classes.heartIcon} onClick={(e) => handleFavorite(e, currentUserDetails)} />
                      ) : (
                        <FavoriteIconInactive className={classes.heartIcon} onClick={(e) => handleFavorite(e, currentUserDetails)} />
                      )}
                    </ListItem>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    ref={chatContainer}
                    sx={!emoji && !stickerMenu && !uploadModal && !recentEmojisVisibility ? {
                      minHeight: '390px',
                      maxHeight: '390px',
                      overflowY: 'scroll',
                    } : recentEmojisVisibility ? {
                      minHeight: '370px',
                      maxHeight: '370px',
                      overflowY: 'scroll',
                    } :
                      {
                        minHeight: '120px',
                        maxHeight: '120px',
                        // overflowY: 'scroll',
                      }}
                    className={`${classes.chatContainer} chat-container`}
                    onScroll={onScroll}
                  >
                    <Grid container>
                      {noOldMessages ? <Grid item xs={12} display='flex' justifyContent={'center'}>
                        <Typography
                          variant="body1"
                          component="p"
                          sx={{
                            color: '#2887c9',
                            fontSize: '12px',
                            fontWeight: '600',
                          }}
                        >
                          {t("noPreviousMessage")}
                        </Typography></Grid> : ''}
                      {recentMessages.length > 0 ? (
                        recentMessages.map((item) =>
                          item.first_message != 1 &&
                            item.user_id === footerData.user_id ? (
                            <>
                              <Grid item xs={12} sx={{ marginRight: '1rem',mt:1 }} key={item.message_id}>
                                {item.content != null ? (
                                  <Typography
                                    className={classes.receiverMsg}
                                    variant="body1"
                                    component="p"
                                    sx={{
                                      color: '#fff',
                                      fontSize: '12px',
                                      fontWeight: '600',
                                    }}
                                  >
                                    {item.content}
                                  </Typography>
                                ) : (
                                  ''
                                )}
                                {item.name != null && item.type === 2 ? (
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                      backgroundColor: `#2887C9`,
                                      padding: '10px',
                                      float: 'right',
                                      borderRadius: '10px',
                                      marginTop: '20px',
                                    }}
                                  >
                                    <img
                                      src={`${item.name}`}
                                      alt=""
                                      loading="lazy"
                                      style={{
                                        width: '200px',
                                        height: '200px',
                                      }}
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                                {item.name != null && item.type === 4 ? (
                                  <div
                                    className={classes.voiceMessangeSender}
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                    }}
                                  >
                                    <VoicePlay
                                      voice={item.name}
                                      duration={`${item.minutes <= 9
                                        ? `0${item.minutes}`
                                        : item.minutes
                                        }:${item.seconds <= 9
                                          ? `0${item.seconds}`
                                          : item.seconds
                                        }`}
                                      fill={'#fff'}
                                      mins={
                                        item.minutes > 0 && item.minutes <= 9
                                          ? `0${item.minutes}`
                                          : item.minutes
                                      }
                                      secs={
                                        item.seconds <= 9
                                          ? `0${item.seconds}`
                                          : item.seconds
                                      }
                                    />
                                  </div>
                                ) : (
                                  ''
                                )}
                              </Grid>

                              {item.sticker_name != null ? (
                                <Grid item xs={12} sx={{ marginRight: '10px' }}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'end',
                                    }}
                                  >
                                    <ChatStickers
                                      source={item.unlocked_img}
                                      height={70}
                                      width={70}
                                      margin={5}
                                      id={item.sticker_id}
                                      havingCallbackFunction={false}
                                      uniCode={null}
                                    />
                                  </div>
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <Typography
                                  variant="subtitle2"
                                  component="p"
                                  sx={{
                                    fontWeight: 600,
                                    color: '#7a797a',
                                    marginRight: '1rem',
                                    textAlign: 'right',
                                    mb: 1,
                                  }}
                                // className={`${
                                //   item.message_id === messageId
                                //     ? 'Newmessage'
                                //     : ''
                                // }`}
                                >
                                  {getTimeStamp(item.created_at)}
                                </Typography>
                              </Grid>
                            </>
                          ) : item.first_message != 1 ? (
                            <>
                              <Grid container xs={12} sx={{ mt: 3 }}>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                  {/* <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{
                                      vertical: 'bottom',
                                      horizontal: 'right',
                                    }}
                                    variant="dot"
                                    style={
                                      item.name != null
                                        ? {
                                            '& .MuiBadge-badge': {
                                              bottom: '78% !important',
                                            },
                                          }
                                        : {}
                                    }
                                  > */}
                                  <Avatar
                                    alt="avatar"
                                    src={`${currentUserDetails.pictures}`}
                                    sx={{
                                      height: '60px',
                                      width: '60px',
                                      // marginRight: '10px',
                                      // marginLeft: '10px',
                                      border: '2px solid #F7B573',
                                    }}
                                  />
                                  {/* </StyledBadge> */}
                                </Grid>
                                <Grid item xs={8} sm={8} md={8} lg={8} sx={{m:'10px 0px 0px 10px'}}>
                                  {item.content != null ? (
                                    <Typography
                                      className={classes.senderMsg}
                                      variant="body1"
                                      component="p"
                                      sx={{
                                        color: '#202020',
                                        fontSize: '12px',
                                        fontWeight: '600',
                                        ml: 1,
                                      }}
                                    >
                                      {item.content}
                                    </Typography>
                                  ) : (
                                    ''
                                  )}
                                  {item.name != null && item.type === 2 ? (
                                    <Grid
                                      item
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      sx={{
                                        ml: 2,
                                        mt: 1,
                                        mr: 1,
                                      }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          backgroundColor: `#ffffff`,
                                          padding: '10px',
                                          float: 'left',
                                          borderRadius: '10px',
                                          marginTop: '20px',
                                        }}
                                      >
                                        <img
                                          src={`${item.name}`}
                                          alt=""
                                          loading="lazy"
                                          style={{
                                            width: '200px',
                                            height: '200px',
                                          }}
                                        />
                                      </div>
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                  {item.name != null && item.type === 4 ? (
                                    <Grid
                                      item
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      sx={{
                                        backgroundColor: '#ffffff',
                                        ml: 4,
                                        mt: 1,
                                        mr: 1,
                                        width: '500px',
                                      }}
                                      className={classes.triRightLeftInafter}
                                    >
                                      <VoicePlay
                                        voice={item.name}
                                        duration={`${item.minutes <= 9
                                          ? `0${item.minutes}`
                                          : item.minutes
                                          }:${item.seconds <= 9
                                            ? `0${item.seconds}`
                                            : item.seconds
                                          }`}
                                        fill={'#2887C9'}
                                        mins={
                                          item.minutes > 0 && item.minutes <= 9
                                            ? `0${item.minutes}`
                                            : item.minutes
                                        }
                                        secs={
                                          item.seconds <= 9
                                            ? `0${item.seconds}`
                                            : item.seconds
                                        }
                                      />
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                  {item.sticker_name != null ? (
                                    <Grid
                                      item
                                      xs={8}
                                      sm={8}
                                      md={8}
                                      lg={8}
                                      sx={{ ml: 5 }}
                                    >
                                      <div
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'start',
                                        }}
                                      >
                                        <ChatStickers
                                          source={item.unlocked_img}
                                          height={70}
                                          width={70}
                                          margin={5}
                                          id={item.sticker_id}
                                          havingCallbackFunction={false}
                                          uniCode={null}
                                        />
                                      </div>
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                                </Grid>
                                {/* {item.type != 2 &&
                                  item.sticker_name === null ? (
                                  <Grid
                                    item
                                    xs={1}
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ mr: 3 }}
                                  >
                                    <SmileyIcon />
                                  </Grid>
                                ) : (
                                  ''
                                )} */}
                                <Grid item xs={12} sx={item.content != null ? { mt: -1.9 } : {}}>
                                  <Typography
                                    variant="subtitle2"
                                    component="p"
                                    sx={{
                                      fontWeight: 600,
                                      color: '#7a797a',
                                      marginLeft: '5.5rem',
                                    }}
                                  // className={`${
                                  //   item.message_id === messageId
                                  //     ? 'Newmessage'
                                  //     : ''
                                  // }`}
                                  >
                                    {getTimeStamp(item.created_at)}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            ''
                          )
                        )
                      ) : (
                        <Grid item sx={{ mt: 30, marginLeft: '50%' }}>
                          {meassageLoader ? (
                            <CircularProgress color="secondary" />
                          ) : (
                            ''
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {emoji ? <Grid
                    item
                    xs={12}
                    sx={{
                      minHeight: '300px',
                      maxHeight: '300px',
                      overflow: 'auto',
                      justifyContent: 'center'
                    }}
                    className={classes.messageContainer}
                  >
                    {getEmojis()}
                  </Grid> : ''}
                  {stickerMenu ? <Grid
                    item
                    xs={12}
                    sx={{
                      minHeight: '300px',
                      maxHeight: '300px',
                      overflow: 'auto',
                      justifyContent: 'center'
                    }}
                    className={classes.messageContainer}
                  >
                    {getStickers()}
                  </Grid> : ''}
                  {uploadModal ? <Grid
                    item
                    xs={12}
                    sx={{
                      minHeight: '300px',
                      maxHeight: '300px',
                      overflow: 'auto',
                      justifyContent: 'center'
                    }}
                    className={classes.messageContainer}
                  >
                    {renderImages()}
                  </Grid> : ''}
                  {!recentEmojisVisibility ? <Grid
                    item
                    xs={12}
                    sx={{
                      minHeight: '30px',
                      maxHeight: '30px',
                      overflow: 'auto',
                      display: 'flex',
                      justifyContent: 'end',

                    }}
                    className={classes.messageContainer}
                  >
                    <RecentShowIcon title={t("recentEmojis")} style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => showHideRecentEmojies()} />
                  </Grid> : ''}
                  {recentEmojisVisibility ? <Grid item xs={12}
                    sx={{
                      minHeight: '50px',
                      maxHeight: '50px',
                      display: 'flex',
                      justifyContent: 'center',

                    }}
                    className={classes.messageContainer}>
                    <Grid container>
                      <Grid item xs={11.5} sx={{ pr: 0, mr: 0 }}>{showRecentEmojies()}</Grid>
                      <Grid item xs={0.5} sx={{  pl: 0, ml: -4.5 }}>
                        <RecentHideIcon style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => setRecentEmojisVisibility(false)} /></Grid>
                    </Grid>

                  </Grid> : ''}
                  <Grid
                    item
                    xs={12}
                    sx={{
                      minHeight: '70px',
                      maxHeight: '70px',
                      overflow: 'auto',
                    }}
                    className={classes.messageContainer}
                  >
                    <TextField
                      autoComplete="off"
                      // onPaste={(e) => {
                      //   e.preventDefault()
                      //   return false;
                      // }}
                      onChange={(e) => handleTextChange(e)}
                      onKeyDown={(e) => handleTextChange(e)}
                      tabIndex='0'
                      variant="filled"
                      name="msg"
                      value={text}
                      placeholder={t("typeYourmessageHere")}
                      sx={{ position: 'absolute', width: '97%', bottom: 13, paddingRight: '0px !important', fontSize: 'large' }}
                      InputProps={{
                        sx: { height: '3.4375em !important' },
                        classes: {
                          input: classes.inputFields,
                          root: classes.inputRoot,
                        },
                        disableUnderline: true,
                        startAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.startAdorn}
                          >
                            {props.voiceUnlocked ? <AudioIcon
                              style={{ cursor: 'pointer' }}
                              onClick={
                                props.nextMessageStatus
                                  ? handleAudioStartstop('top')
                                  : () => setCommonAlertModal(true)
                              }
                            /> : <MicIcon />}
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment
                            position="start"
                            className={classes.startAdorn}
                          >
                            <GalleryIcon
                              onClick={
                                props.nextMessageStatus
                                  ? handleImageSelect('top-center')
                                  : () => setCommonAlertModal(true)
                              }
                              style={{ marginRight: '8px', cursor: 'pointer' }}
                              title={t("sendPhoto")}
                            />

                            <StickerIcon
                              style={{ marginRight: '8px', cursor: 'pointer' }}
                              onClick={props.nextMessageStatus ? handleClick('top')
                                : () => setCommonAlertModal(true)}
                                title={t("sendStickers")}
                            />
                            <SmileyIcon
                             title={t("sendEmoji")}
                              onClick={
                                props.nextMessageStatus
                                  ? handleEmojiSelect('top')
                                  : () => setCommonAlertModal(true)
                              }
                              style={{ marginRight: '8px', cursor: 'pointer' }}
                            />
                            <Button
                              color={
                                props.nextMessageStatus
                                  ? 'primary'
                                  : 'secondary'
                              }
                              variant="contained"
                              sx={{
                                borderRadius: '10px',
                                padding: '2px 15px',
                                marginRight: '-4px',
                              }}
                              type="submit"
                              onClick={
                                props.nextMessageStatus
                                  ? () => sendMessage()
                                  : () => setCommonAlertModal(true)
                              }
                            >
                              {t("send")}
                            </Button>
                          </InputAdornment>
                        ),
                      }}
                      fullWidth
                    />
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Card>
      </>
    );
  };

  const showRecentEmojies = () => {
    return (<Paper
      sx={{
        width: '98%',
        minHeight: !isMobile ? '50px' : "auto",
        maxHeight: !isMobile ? '50px' : "auto",
        borderRadius: '5px',
        ml: 1.5,
        mr: 5
      }}
    >
      <Grid container>
        {isMobile ? <Grid item xs={12} sx={{ m: 0, p: 0,}} display='flex' justifyContent='end'>
          <CloseIcon style={{ marginRight: '5px', cursor: 'pointer' }} onClick={() => setRecentEmojisVisibility(false)} /></Grid> : ""}
        {recentEmojiesList.length > 0 ? recentEmojiesList.map((item) => (
          item.type === 1 && (item.character_code !== null && item.character_code !== undefined) ?
            <>
              <Grid key={item.sticker_id} item xs={6} sm={4} md={3} lg={1.1} sx={{ p: 0, mb: 1, mt: 1, ml: 0, mr: 0 }} display='flex' justifyContent='center'>
                <ChatStickers
                  source={item.unlocked_img}
                  height={30}
                  width={30}
                  margin={5}
                  sendMessage={addToText}
                  id={item.sticker_id}
                  havingCallbackFunction={true}
                  uniCode={item.character_code.replace("U+", "")}
                />
              </Grid>
            </> : <Grid key={item.sticker_id} item xs={6} sm={4} md={3} lg={1.1} sx={{ p: 0, mb: 1, mt: 1, ml: 0, mr: 0 }} display='flex' justifyContent='center'>
              <ChatStickers
                source={item.unlocked_img}
                height={30}
                width={30}
                margin={5}
                sendMessage={sendMessage}
                id={item.sticker_id}
                havingCallbackFunction={true}
                uniCode={null}
              />
            </Grid>
        )) : <Grid item xs={12} justifyContent={'center'} display={'flex'}><Typography>{t("NoSmileys")}</Typography></Grid>}
      </Grid></Paper>)
  }
  const emojis = (emojidata) => {
    var hexPoints = emojidata.split(" ");
    var codePoints = hexPoints.map(function operator(hex) {
      return parseInt(hex, 16);
    });
    return String.fromCodePoint.apply(null, codePoints);
  };

  const getEmojis = () => {
    return (
      <Paper
        sx={{
          width: '97%',
          minHeight: '300px',
          maxHeight: '300px',
          overflow: 'auto',
          borderRadius: '5px',
          '&::-webkit-scrollbar': {
            width: '5px'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: ' 0 0 5px grey',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray',
            borderRadius: '10px',
          }
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 0, mb: 0, mt: 0 }} display='flex' justifyContent='end'>
            <CloseIcon onClick={() => setEmoji(false)} style={{ cursor: 'pointer' }} />
          </Grid>
          {totalStickerList.length > 0 ? totalStickerList.map((item) => (
            item.type === 1 && (item.character_code !== null && item.character_code !== undefined) ?
              <>
                <Grid key={item.sticker_id} item xs={6} sm={4} md={3} lg={1.5} sx={{ p: 0, mb: 1, mt: 1, display: 'flex', justifyContent: 'center' }}>
                  <ChatStickers
                    source={item.unlocked_img}
                    height={50}
                    width={50}
                    margin={10}
                    sendMessage={addToText}
                    id={item.sticker_id}
                    havingCallbackFunction={true}
                    uniCode={item.character_code.replace("U+", "")}
                  />
                  {/* <p
                    onClick={() => addToText(item.character_code.replace("U+", ""))}
                    style={{ marginTop: '0px', marginBottom: '5px', cursor: 'pointer', fontSize: 'xxx-large' }}
                  >
                    {emojis(item.character_code.replace("U+", ""))}
                  </p> */}
                </Grid>
              </> : ''
          )) : <Grid item xs={12} justifyContent={'center'} display={'flex'}><Typography>{t("NoSmileys")}</Typography></Grid>}

        </Grid>
      </Paper>
    )
  }
  const getStickers = () => {
    return (
      <Paper
        sx={{
          width: '97%',
          minHeight: '300px',
          maxHeight: '300px',
          overflow: 'auto',
          borderRadius: '5px',
          '&::-webkit-scrollbar': {
            width: '5px'
          },
          '&::-webkit-scrollbar-track': {
            boxShadow: ' 0 0 5px grey',
            borderRadius: '10px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'gray',
            borderRadius: '10px',
          }
        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 0, mb: 0, mt: 0 }} display='flex' justifyContent='end'>
            <CloseIcon onClick={() => setStickerMenu(false)} style={{ cursor: 'pointer' }} />
          </Grid>
          {totalStickerList.length > 0 ? totalStickerList.map((item) => (
            item.type === 2 || item.character_code === null ?
              <>
                <Grid key={item.sticker_id} item xs={6} sm={4} md={4} lg={2} sx={{ padding: ' 0px 32px', mb: 0, mt: 0 }}>
                  <ChatStickers
                    source={item.unlocked_img}
                    height={90}
                    width={90}
                    margin={5}
                    sendMessage={sendMessage}
                    id={item.sticker_id}
                    havingCallbackFunction={true}
                    uniCode={null}
                  />
                </Grid>
              </> : ''
          )) : (<Grid item xs={12} justifyContent={'center'} display={'flex'}><Typography>{t("NoStickers")}</Typography></Grid>)}
        </Grid>
      </Paper>
    )
  }

  const renderImages = () => {
    return (
      <Paper
        sx={{
          width: '97%',
          minHeight: '300px',
          maxHeight: '300px',
          borderRadius: '5px',
          overflow: 'hidden'

        }}
      >
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 0, mb: 0, mt: 0 }} display='flex' justifyContent='end'>
            <CloseIcon onClick={() => setUploadModal(false)} style={{ cursor: 'pointer' }} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} sx={{ p: 0, mb: 0, mt: 0 }} display='flex' justifyContent='center'>
            {props.photoList.length > 0 ? (
              <ImageList cols={3} sx={{
                height: 250, '&::-webkit-scrollbar': {
                  width: '5px'
                },
                '&::-webkit-scrollbar-track': {
                  boxShadow: ' 0 0 5px grey',
                  borderRadius: '10px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: 'gray',
                  borderRadius: '10px',
                }
              }}>
                {props.photoList.map((item) => (
                  <>
                    <ImageListItem key={item.gallery_id} sx={{ ml: 1, height: '250px', width: '250px' }}>
                      <img
                        src={`${item.pictures}`}
                        alt={item.title}
                        loading="lazy"
                        style={{ height: '250px', objectFit: 'contain' }}
                      />
                      <ImageListItemBar
                        title={item.description}
                        actionIcon={
                          <IconButton
                            sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                            aria-label={`info about ${item.title}`}
                          >
                            <SendIcon
                              onClick={() => sendPicConfirm(item)}
                            />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  </>))}
              </ImageList>
            ) : (
              <Grid justifyContent={'center'} display={'flex'}>
                <Typography>{t("NoGalleryImages")}</Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper >
    )
  }

  const flipMobileChat = () => {
    setIsFliped(false);
    setEmoji(false);
    setUploadModal(false);
    setRecordinStatus(false);
    setStickerMenu(false);
    props.getChatHistoryData(localStorage.getItem('userToken'));
  }
  const renderMobile = () => {
    return (
      <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
        <Card>
          <Grid item xs={12}>
            <List
              sx={{ maxHeight: '500px', overflow: 'auto', cursor: 'pointer' }}
            >
              {chatHistory.length > 0 ? (
                chatHistory.map((item) => {
                  return (
                    <div key={item.user_id}>
                      <ListItem
                        key={item.user_id}
                        divider={true}
                        sx={{
                          padding: '10px 24px',
                          borderBottom: '0px',
                        }}
                        onClick={() => getCurrentChatDetails(item)}
                      >
                        {item.is_favourite === 1 ? (
                            <div>
                          <FavoriteIcon className={classes.heartIcon} />
                              <div onClick={(e)=>handleDeleteButton(e, item)} className={classes.trashIcon}><Trash/></div>
                            </div>
                        ) : (
                            <div>
                          <FavoriteIconInactive className={classes.heartIcon} />
                              <div onClick={(e)=>handleDeleteButton(e, item)} className={classes.trashIcon}><Trash/></div>

                            </div>
                        )}
                        <ListItemAvatar>
                          {item.is_online === 1 ? (
                            <StyledBadge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              variant="dot"
                            >
                              <Avatar
                                alt="avatar"
                                src={`${item.pictures}`}
                                sx={{
                                  height: '70px',
                                  width: '70px',
                                  marginRight: '10px',
                                  marginLeft: '10px',
                                  border: '2px solid #F7B573',
                                }}
                              />
                            </StyledBadge>
                          ) : (
                            <Avatar
                              alt="avatar"
                              src={`${item.pictures}`}
                              sx={{
                                height: '70px',
                                width: '70px',
                                marginRight: '10px',
                                marginLeft: '10px',
                                border: '2px solid #F7B573',
                              }}
                            />
                          )}
                        </ListItemAvatar>
                        {!expand ? (
                          <ListItemText
                            primary={
                              <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="body1"
                                  component="p"
                                  sx={{ color: '#202020' }}
                                >
                                  {item.username},&nbsp;{' '}
                                  {item.gender === 'Male'
                                    ? 'M'
                                    : item.gender === 'Female'
                                      ? 'F'
                                      : ''}
                                  &nbsp; {item.age === 0 ? '' : item.age}
                                </Typography>
                              </Grid>
                            }
                            secondary={
                              <Grid
                                container
                                alignItems="center"
                                justifyContent="space-between"
                              >
                                <Typography
                                  component="p"
                                  variant="body2"
                                  className={classes.descmsg}
                                >
                                  {item.last_message?.length > 10
                                    ? `${item.last_message?.slice(0, 10)}...`
                                    : item.last_message}
                                </Typography>
                              </Grid>
                            }
                          />
                        ) : (
                          ''
                        )}
                      </ListItem>
                      <Divider sx={{ marginLeft: '-24px' }} />
                    </div>
                  );
                })
              ) : (
                <Typography variant="body1" component="radialGradient">
                  {t("noMessages")}
                </Typography>
              )}
            </List>
            <Grid
              item xs={12}
              sx={{  cursor: 'pointer' }}
              onClick={() => luckyShot()}
            >
              <List sx={{p:0}}>
          <ListItem sx={{pl:1,p:0}}>
            <ListItemButton>
              <ListItemIcon>
              <LuckyShotIcon />
              </ListItemIcon>
              <ListItemText
              sx={{pl:1}}
              primary=  {t("footerFlower")}
              primaryTypographyProps={{
                color:'#6f6868'
              }} />
            </ListItemButton>
          </ListItem>
          </List>
            </Grid>
          </Grid>
        </Card>

        <div>
          <Card>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12} sx={{ minHeight: '90px', maxHeight: '90px' }}>
                <ListItem sx={{ padding: '10px 24px' }}>
                  <ListItemIcon>
                    <ExpandCollapseIcon onClick={() => flipMobileChat()} />
                  </ListItemIcon>
                  <ListItemAvatar>
                    {currentUserDetails.is_online === 1 ? (
                      <StyledBadge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        variant="dot"
                      >
                        <Avatar
                          alt="avatar"
                          src={`${currentUserDetails.pictures}`}
                          sx={{
                            height: '70px',
                            width: '70px',
                            marginRight: '10px',
                            marginLeft: '10px',
                            border: '2px solid #F7B573',
                          }}
                        />
                      </StyledBadge>
                    ) : (
                      <Avatar
                        alt="avatar"
                        src={`${currentUserDetails.pictures}`}
                        sx={{
                          height: '70px',
                          width: '70px',
                          marginRight: '10px',
                          marginLeft: '10px',
                          border: '2px solid #F7B573',
                        }}
                      />
                    )}
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography
                          variant="body1"
                          component="h6"
                          sx={{ color: '#202020' }}
                        >
                          {currentUserDetails.username},&nbsp;
                          {currentUserDetails.gender === 'Male'
                            ? 'M'
                            : currentUserDetails.gender === 'Female'
                              ? 'F'
                              : ''}
                          &nbsp;{' '}
                          {currentUserDetails.age === 0
                            ? ''
                            : currentUserDetails.age}
                        </Typography>
                      </Grid>
                    }
                  />
                  {currentUserDetails.is_favourite === 1 ? (

                    <FavoriteIcon className={classes.heartIcon} onClick={(e) => handleFavorite(e, currentUserDetails)} />
                  ) : (
                    <FavoriteIconInactive className={classes.heartIcon} onClick={(e) => handleFavorite(e, currentUserDetails)} />
                  )}
                </ListItem>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                ref={chatContainer}
                sx={{
                  minHeight: '450px',
                  maxHeight: '450px',
                  overflowY: 'scroll',
                }}
                className={classes.chatContainer}
              >
                <Grid container sx={{}}>
                  {recentMessages.length > 0 ? (
                    recentMessages.map((item) =>
                      item.first_message !== 1 &&
                        item.user_id === footerData.user_id ? (
                        <>
                          <Grid key={item} item xs={12} sx={{ marginRight: '1rem' }}>
                            {item.content != null ? (
                              <Typography
                                className={classes.receiverMsg}
                                variant="body1"
                                component="p"
                                sx={{
                                  color: '#fff',
                                  fontSize: '12px',
                                  fontWeight: '600',
                                }}
                              >
                                {item.content}
                              </Typography>
                            ) : (
                              ''
                            )}
                            {item.name != null && item.type === 2 ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                  backgroundColor: `#2887C9`,
                                  padding: '10px',
                                  float: 'right',
                                  borderRadius: '10px',
                                  marginTop: '20px',
                                }}
                              >
                                <img
                                  src={`${item.name}`}
                                  alt=""
                                  loading="lazy"
                                  style={{
                                    width: '200px',
                                    height: '200px',
                                  }}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                            {item.name != null && item.type === 4 ? (
                              <div
                                className={classes.voiceMessangeSender}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                }}
                              >
                                <VoicePlay
                                  voice={item.name}
                                  duration={`${item.minutes <= 9
                                    ? `0${item.minutes}`
                                    : item.minutes
                                    }:${item.seconds <= 9
                                      ? `0${item.seconds}`
                                      : item.seconds
                                    }`}
                                  fill={'#fff'}
                                  mins={
                                    item.minutes > 0 && item.minutes <= 9
                                      ? `0${item.minutes}`
                                      : item.minutes
                                  }
                                  secs={
                                    item.seconds <= 9
                                      ? `0${item.seconds}`
                                      : item.seconds
                                  }
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </Grid>

                          {item.sticker_name != null ? (
                            <Grid item xs={12} sx={{ marginRight: '10px' }}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'end',
                                }}
                              >
                                <ChatStickers
                                  source={item.unlocked_img}
                                  height={70}
                                  width={70}
                                  margin={5}
                                  id={item.sticker_id}
                                  havingCallbackFunction={false}
                                  uniCode={null}
                                />
                              </div>
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <Typography
                              variant="subtitle2"
                              component="p"
                              sx={{
                                fontWeight: 600,
                                color: '#7a797a',
                                marginRight: '1rem',
                                textAlign: 'right',
                                mb: 1,
                              }}
                            // className={`${
                            //   item.message_id === messageId
                            //     ? 'Newmessage'
                            //     : ''
                            // }`}
                            >
                              {getTimeStamp(item.created_at)}
                            </Typography>
                          </Grid>
                        </>
                      ) : item.first_message != 1 ? (
                        <>
                          <Grid container xs={12} sx={{ mt: 3 }}>
                            <Grid item xs={1} sm={1} md={1} lg={1}>
                              <StyledBadge
                                overlap="circular"
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                variant="dot"
                                style={
                                  item.name != null
                                    ? {
                                      '& .MuiBadge-badge': {
                                        bottom: '78% !important',
                                      },
                                    }
                                    : {}
                                }
                              >
                                <Avatar
                                  alt="avatar"
                                  src={`${currentUserDetails.pictures}`}
                                  sx={{
                                    height: '40px',
                                    width: '40px',
                                    // marginRight: '10px',
                                    // marginLeft: '10px',
                                    border: '2px solid #F7B573',
                                  }}
                                />
                              </StyledBadge>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8}>
                              {item.content != null ? (
                                <Typography
                                  className={classes.senderMsg}
                                  variant="body1"
                                  component="p"
                                  sx={{
                                    color: '#202020',
                                    fontSize: '12px',
                                    fontWeight: '600',
                                    ml: 4,
                                  }}
                                >
                                  {item.content}
                                </Typography>
                              ) : (
                                ''
                              )}
                              {item.name != null && item.type === 2 ? (
                                <Grid
                                  item
                                  xs={12}
                                  sx={{
                                    ml: 2,
                                    mt: 1,
                                    mr: 1,
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      backgroundColor: `#ffffff`,
                                      padding: '10px',
                                      float: 'left',
                                      borderRadius: '10px',
                                      marginTop: '20px',
                                    }}
                                  >
                                    <img
                                      src={`${item.name}`}
                                      alt=""
                                      loading="lazy"
                                      style={{
                                        width: '200px',
                                        height: '200px',
                                      }}
                                    />
                                  </div>
                                </Grid>
                              ) : (
                                ''
                              )}
                              {item.name != null && item.type === 4 ? (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  sx={{
                                    backgroundColor: '#ffffff',
                                    ml: 4,
                                    mt: 1,
                                    mr: 1,
                                    width: '500px',
                                  }}
                                  className={classes.triRightLeftInafter}
                                >
                                  <VoicePlay
                                    voice={item.name}
                                    duration={`${item.minutes <= 9
                                      ? `0${item.minutes}`
                                      : item.minutes
                                      }:${item.seconds <= 9
                                        ? `0${item.seconds}`
                                        : item.seconds
                                      }`}
                                    fill={'#2887C9'}
                                    mins={
                                      item.minutes > 0 && item.minutes <= 9
                                        ? `0${item.minutes}`
                                        : item.minutes
                                    }
                                    secs={
                                      item.seconds <= 9
                                        ? `0${item.seconds}`
                                        : item.seconds
                                    }
                                  />
                                </Grid>
                              ) : (
                                ''
                              )}
                              {item.sticker_name != null ? (
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={12}
                                  sx={{ ml: 5 }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'start',
                                    }}
                                  >
                                    <ChatStickers
                                      source={item.unlocked_img}
                                      height={70}
                                      width={70}
                                      margin={5}
                                      id={item.sticker_id}
                                      havingCallbackFunction={false}
                                      uniCode={null}
                                    />
                                  </div>
                                </Grid>
                              ) : (
                                ''
                              )}
                            </Grid>
                            {/* {item.type != 2 && item.sticker_name === null ? (
                              <Grid
                                item
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                sx={{ mr: 3 }}
                              >
                                <SmileyIcon />
                              </Grid>
                            ) : (
                              ''
                            )} */}
                            <Grid item xs={12}>
                              <Typography
                                variant="subtitle2"
                                component="p"
                                sx={{
                                  fontWeight: 600,
                                  color: '#7a797a',
                                  marginLeft: '6rem',
                                }}
                              // className={`${
                              //   item.message_id === messageId
                              //     ? 'Newmessage'
                              //     : ''
                              // }`}
                              >
                                {getTimeStamp(item.created_at)}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        ''
                      )
                    )
                  ) : (
                    <Grid item sx={{ mt: 10, marginLeft: '50%' }}>
                      {meassageLoader ? (
                        <CircularProgress color="secondary" />
                      ) : (
                        ''
                      )}
                    </Grid>
                  )}
                </Grid>
                {emoji ? <Grid
                  item
                  xs={12}
                  sx={{
                    minHeight: '300px',
                    maxHeight: '300px',
                    overflow: 'auto',
                    justifyContent: 'center'
                  }}
                  className={classes.messageContainer}
                >
                  {getEmojis()}
                </Grid> : ''}
                {stickerMenu ? <Grid
                  item
                  xs={12}
                  sx={{
                    minHeight: '300px',
                    maxHeight: '300px',
                    overflow: 'auto',
                    justifyContent: 'center'
                  }}
                  className={classes.messageContainer}
                >
                  {getStickers()}
                </Grid> : ''}
                {uploadModal ? <Grid
                  item
                  xs={12}
                  sx={{
                    minHeight: '300px',
                    maxHeight: '300px',
                    overflow: 'auto',
                    justifyContent: 'center'
                  }}
                  className={classes.messageContainer}
                >
                  {renderImages()}
                </Grid> : ''}
                {!recentEmojisVisibility ? <Grid
                  item
                  xs={12}
                  sx={{
                    minHeight: '30px',
                    maxHeight: '30px',
                    overflow: 'auto',
                    display: 'flex',
                    justifyContent: 'end',

                  }}
                  className={classes.messageContainer}
                >
                  <RecentShowIcon title={t("recentEmojis")} style={{ marginRight: '20px', cursor: 'pointer' }} onClick={() => showHideRecentEmojies()} />
                </Grid> : ''}
                {recentEmojisVisibility ? <Grid item xs={12}
                  sx={{
                    minHeight: '50px',
                    maxHeight: '50px',
                    display: 'flex',
                    justifyContent: 'center',

                  }}
                  className={classes.messageContainer}>
                  <Grid container>
                    <Grid item xs={11.5} sx={{ pr: 0, mr: 0 }}>{showRecentEmojies()}</Grid>

                  </Grid>

                </Grid> : ''}
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  minHeight: '80px',
                  maxHeight: '80px',
                }}
                className={classes.chatContainer}
              >
                <TextField
                  autoComplete="off"
                  onChange={(e) => handleTextChange(e)}
                  variant="filled"
                  name="msg"
                  value={text}
                  placeholder={t("typeYourmessageHere")}
                  style={{ position: 'absolute', width: '97%', bottom: 13 }}
                  InputProps={{
                    sx: { height: '3.4375em !important', pl: 0.5, pr: 0.5, width: '99%' },
                    classes: {
                      input: classes.inputFields,
                      root: classes.inputRoot,
                    },
                    disableUnderline: true,
                    startAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.startAdorn}
                      >
                        {props.voiceUnlocked ? <AudioIcon
                          style={{ cursor: 'pointer' }}
                          onClick={
                            props.nextMessageStatus
                              ? handleAudioStartstop('top')
                              : () => setCommonAlertModal(true)
                          }
                        /> : <MicIcon />}
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        className={classes.startAdorn}
                      >
                        <GalleryIcon
                          onClick={
                            props.nextMessageStatus
                              ? handleImageSelect('top-center')
                              : () => setCommonAlertModal(true)
                          }
                          style={{ marginRight: '8px', cursor: 'pointer' }}
                          title={t("sendPhoto")}
                        />

                        <StickerIcon
                          style={{ marginRight: '8px', cursor: 'pointer' }}
                          onClick={handleClick('top')}
                          title={t("sendStickers")}
                        />
                        <SmileyIcon
                        title={t("sendEmoji")}
                          onClick={
                            props.nextMessageStatus
                              ? handleEmojiSelect('top')
                              : () => setCommonAlertModal(true)
                          }
                          style={{ marginRight: '8px', cursor: 'pointer' }}
                        />
                        <Button
                          color={
                            props.nextMessageStatus ? 'primary' : 'secondary'
                          }
                          variant="contained"
                          sx={{
                            borderRadius: '10px',
                            padding: '2px 15px',
                            marginRight: '4px',
                          }}
                          type="submit"
                          onClick={
                            props.nextMessageStatus
                              ? () => sendMessage()
                              : () => setCommonAlertModal(true)
                          }
                        >
                          {t("send")}
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Card>
        </div>
      </ReactCardFlip>
    );
  };
  return (
    <UserLayout subHeader={false} subFooter={false} header={true}>
      <OtpVerifyAlert accVerifiedStatus={accVerifiedStatus} removeOTPAlert={removeVerifyAlert}/>
      {initialLoader ? <Loader /> : ''}
      <Alert
        isContainLink={false}
        navigateLink={'false'}
        title={t("noCoins")}
        modal={commonAlertModal}
        message={t("notEnoughCoinsForMessage")}
        buttonText={t("Close")}
        icon={'CoinsNotEnough'}
        removeFunc={setCommonModalFalse}
        closeIcon={true}
      />
      <ConfirmationAlert
        alert={newestMemberAlert}
        confirmButton={alertConfirmButton}
        closeButton={alertCloseButton}
        confirmButtonText={alertConfirmButtonText}
        closeButtonText={alertCloseButtonText}
        alertTitle={alertTitle}
        alertText={alertText}
        closeFunc={CloseAlert}
        confirmFunc={sendGalleryPic}
        buttonType={true}
        buttonDisabled={buttonDisabled}
      />
      {isMobile || window.innerWidth <= 1280 ? renderMobile() : renderWeb()}
      <Popper
        open={recordingStatus}
        anchorEl={anchorEl}
        placement="top-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              sx={{
                mb: 2,
                width: isMobile || window.innerWidth <= 1280 ? '80%' : 'auto',
                borderRadius: '5px',
                overflow: 'auto',
                height: 'auto',
              }}
            >
              <Recorder
                style={{ marginLeft: '20px' }}
                recordState={recordDuration}
                userID={currentUserDetails.user_id}
                closeAudio={handleAudioStartstop}
              />
            </Paper>
          </Fade>
        )}
      </Popper>
    </UserLayout>
  );
};

const mapStateToProps = (state) => {
  return {
    chatHistory: state.ChatReducer.chatHistory,
    postMessageStatus: state.ChatReducer.postMessageStatus,
    recentMessages: state.ChatReducer.recentMessages,
    stickerList: state.ChatReducer.stickerList,
    nextMessageStatus: state.ChatReducer.nextMessageStatus,
    recordDuration: state.ChatReducer.recordDuration,
    photoList: state.GalleryReducer.chatGallery,
    luckyChatStatus: state.ChatReducer.luckyChatStatus,
    messageCount: state.ChatReducer.messageCount,
    favoriteStatus: state.CommonReducer.favoriteStatus,
    stickerUnlocked: state.ChatReducer.stickerUnlocked,
    voiceUnlocked: state.ChatReducer.voiceUnlocked,
    oldMessages: state.ChatReducer.oldMessages,
    recentEmojiesList: state.ChatReducer.recentEmojiesList,
    deleteChatEvent: state.ChatReducer.deleteChatEvent,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGalleryPhotoList: (token) =>
      dispatch(galleryAction.getGalleryPhotoListChat(token)),
    getChatHistoryData: (requestData) =>
      dispatch(chatAction.getChatHistoryData(requestData)),
    deleteChat: (requestData, token) =>
        dispatch(chatAction.deleteChat(requestData, token)),
    getLatestChatData: (requestData, token) =>
      dispatch(chatAction.getLatestChatData(requestData, token)),
    PostNewMessage: (requestData, token) =>
      dispatch(chatAction.PostNewMessage(requestData, token)),
    LuckyChatService: (requestData) =>
      dispatch(chatAction.LuckyChatService(requestData)),
    setNewChatStatusNull: (requestData) =>
      dispatch(chatAction.setNewChatStatusNull(requestData)),
    ReadNewMessage: (requestData, token) =>
      dispatch(chatAction.ReadNewMessage(requestData, token)),
    addorRemoveFavorite: (requestData) =>
      dispatch(commonAction.favoriteOtherMember(requestData)),
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
    addLastVisit: (userId, Token) =>
      dispatch(lastVisitorAction.addUserVisitor(userId, Token)),
    getOldChatData: (requestData, token) =>
      dispatch(chatAction.getOldChatData(requestData, token)),
    getRecentEmojies: (token) =>
      dispatch(chatAction.getRecentEmojies(token)),
    getHeaderData: (requestData) =>
      dispatch(headerAction.getHeaderData(requestData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);

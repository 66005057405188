import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, LinearProgress } from '@mui/material';
import useStyles from '../../styles/footer';
import { connect } from 'react-redux';
import * as commonAction from '../../redux/action/commonAction';
import Timer from '../../utils/timer';
import Pusher from 'pusher-js';
import * as userDetailsAction from '../../redux/action/userDetailsAction';
import * as chestAction from '../../redux/action/chestAction';
import { useTranslation } from 'react-i18next';
const UserSubfooter = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [footerData, setFooterData] = useState([]);
  const [footerUserData, setFooterUserData] = useState([]);
  useEffect(() => {
    props.getFooterData(localStorage.getItem('userToken'));
  }, []);

  useEffect(() => {
    if (props.footerData.length > 0) {
      setFooterData(props.footerData[0]);
    }
  }, [props.footerData]);


  const getTimer = (min, sec) => {
    return (
      <Timer
        min={min}
        sec={sec}
        typeTimer={'footer'}
      />
    );
  };
  useEffect(() => {
    if (footerUserData.user_id > 0) {
      const pusher = new Pusher('e99d25639dee44d98e2b', {
        cluster: 'mt1',
        encrypted: true,
        // authTransport: 'jsonp',
        authEndpoint: `${process.env.REACT_APP_CHAT_URL}GetToken_Pusher`,
        auth: {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('userToken')}`,
            'content-type': 'application/javascript',
            'Access-Control-Allow-Origin': '*',
          },
        },
      });
      const channel = pusher.subscribe(
        `private-FooterDatas.${footerUserData.user_id}`
      );
      channel.bind('App\\Events\\FooterData', (data) => {
        if (data !== undefined) {
           console.log("FOOTER EVENT userSubfooter", data);
          // props.userGeneralDetailsService(localStorage.getItem('userToken'));
          setFooterData(data);
        }
      });
    }
  }, [footerUserData]);
  useEffect(() => {
    if (localStorage.getItem('userLevelData') != null) {
      let data = localStorage.getItem('userLevelData');
      let datas = JSON.parse(data);
      setFooterUserData(datas);
    }
  }, [localStorage.getItem('userToken')]);

  useEffect(() => {
    if (props.selectedTab === 0) {
      props.getFooterData(localStorage.getItem('userToken'));
    } else if (props.selectedTab !== 0) {
      props.UnlockStatusNull()
    }
  }, [props.selectedTab])

  return (
    <>
      <Card
        sx={{
          borderRadius: '10px',
          margin: '20px 0px',
          padding: '18px',
        }}
      >
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={3} lg={2}>
            <Typography variant="body1" component="h6">
             {t("currentMessage")}:{' '}
              <span className={classes.currentMsg}>
                {footerData ? footerData.unread_messages : ''}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="body1" component="p">
              {t("yourChest")}:&nbsp;
              {footerData.recent_chest === null ? (
                <span className={classes.currentMsg}>{t("noChests")}</span>
              ) : footerData.recent_chest != undefined &&
                (footerData.recent_chest.remaining_min > 0 ||
                  footerData.recent_chest.remaining_sec > 0) ? (
                <span
                  style={{
                    backgroundColor: '#FF5B5B',
                    color: '#fff',
                    padding: ' 0px 5px',
                    fontWeight: 400,
                    borderRadius: '5px',
                  }}
                >
                  {getTimer(
                    footerData.recent_chest.remaining_min,
                    footerData.recent_chest.remaining_sec
                  )}
                </span>
              ) : (
                <span className={classes.currentMsg}>00:00 </span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="body1" component="h6">
             {t("currentLevel")}:{' '}
              <span className={classes.currentMsg}>
                {footerData ? footerData.level : ''}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Grid container alignItems="center" justifyContent="start">
              <Typography variant="body1" component="h6">
              {t("progress")}
              </Typography>
              <LinearProgress
                value={footerData ? footerData.progress : ''}
                variant="determinate"
                size={100}
                thickness={6}
                color="success"
                classes={{
                  determinate: classes.profileProgress,
                  root: classes.progressBar,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <Typography variant="body1" component="h6">
              Coins:{' '}
              <span className={classes.coins}>
                {footerData ? footerData.tot_coins : ''}
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    otherUserDetails: state.CommonReducer.userDataById,
    footerData: state.CommonReducer.footerData,
    selectedTab: state.ProfileTabReducer.selectedTab,
    chestData: state.ChestReducer.chestData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserByID: (token, requestData) =>
      dispatch(commonAction.getUserByID(token, requestData)),
    getFooterData: (token) => dispatch(commonAction.getFooterData(token)),
    userGeneralDetailsService: (requestData) =>
      dispatch(userDetailsAction.userGeneralDetailsService(requestData)),
    UnlockStatusNull: () => dispatch(chestAction.UnlockStatusNull()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserSubfooter);

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UserLayout from '../layouts/userLayout';
import MobileLayout from '../layouts/mobileLayout';
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  Snackbar, Modal,
} from '@mui/material';
import PaymentAlert from '../components/common/paymentAlert';
import * as packageAction from '../redux/action/packageAction';
import * as headerAction from '../redux/action/headerAction';
import * as commonAction from '../redux/action/commonAction';
import { useHistory } from 'react-router';
import CornerRibbon from 'react-corner-ribbon';
import { getIsMobile } from '../utils';
import { useTranslation } from 'react-i18next';
import Paypal from '../utils/Paypal';
import axios from "axios"
//import Googlepay from '../utils/Googlepay';
//import Applepay from '../utils/Applepay';

import {ReactComponent as Micro} from "../components/icons/micropayment-ch.svg";
import {ReactComponent as Pp} from "../components/icons/pp_fc_hl.svg";
import Loader from "../utils/loader";

const CoinPackage = (props) => {
  const { t } = useTranslation();
  const [payNow, setPayNow] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const [commonAlertModal, setCommonAlertModal] = useState(false);
  const [commonAlertMessage, setCommonAlertMessage] = useState('');
  const [commonAlertButtonText, setCommonAlertButtonText] = useState('');
  const [IconName, setIconName] = useState('');
  const [commonTitle, setCommonAlertTitle] = useState('');
  const [containLink, setContainLink] = useState(false);
  const [navigationLink, setNavigateLink] = useState('');
  const [closeIcon, setCloseIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [payMethod, setPayMethod] = useState(0)
  const [packageLinks, setPackageLinks] = useState(null)
  const mockData = {
    1: '#764DA0',
    2: '#00A5B9',
    3: '#00A5B9',
    4: '#00A5B9',
    5: '#00A5B9',
    6: '#F4AE18',
  };
  const history = useHistory();
  const isMobile = getIsMobile();
  const Layout = isMobile ? MobileLayout : UserLayout;
  useEffect(() => {
    if (localStorage.getItem('userToken') === null) {
      history.push('/');
    }
  }, [localStorage.getItem('userToken')]);

  useEffect(() => {
    if (localStorage.getItem('userToken') != null) {
      props.getPackageDetails(localStorage.getItem('userToken'));
    }
  }, []);
  useEffect(() => { }, [props.packageList]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const getPaymentDetails = (payDetails) => {
    handleOpen()
    setPaymentData(payDetails)
    //setPayNow(true);
  }

  const handlePayPal = () =>{
    setPayMethod(1);
    handleClose()
    setPayNow(true)
  }

  const handleMicro = () =>{
    handleClose();
    getPayMethods();
    setPayMethod(2)
    setPayNow(true)
  }


const getPayMethods = ()=>{
  const token = localStorage.getItem('userToken')
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },}
  axios.post(`${process.env.REACT_APP_BACKEND_URL}init_mcp_transaction`, {package_id: paymentData.id}, config)
      .then((response)=>{
        if (response.data && response.data.payment_link && response.data.payment_link.length > 0) {
          setPackageLinks(response.data.payment_link)
        }


      }).catch((err)=>{
        cancelPayment();
        console.log("ERROR IN GET MICROPAYMENT", err)
  })
}





  const setCommonModalFalse = () => {
    setCommonAlertModal(false);
    setCommonAlertMessage('');
    setCommonAlertButtonText('');
    setIconName('');
    setCommonAlertTitle('');
    setContainLink(false);
    setNavigateLink('');
    setCloseIcon(false);
  };
  const createOrder = (data, actions) => {
    // return actions.order
    //   .create({
    //     purchase_units: [
    //       {
    //         description: "Purchase Coins on Iseeju",
    //         amount: {
    //           currency_code: "USD",
    //           value: parseFloat(paymentData.price),
    //         },
    //       },
    //     ],
    //     application_context: {
    //       shipping_preference: "NO_SHIPPING",
    //     },
    //   })
    //   .then((orderID) => {
    //     sessionStorage.setItem("OrderID", orderID)
    //     let purchaseData = new FormData();
    //     purchaseData.append("package_id", paymentData.id)
    //     purchaseData.append("order_id", orderID)
    //     purchaseData.append("type", 1)
    props.postPurchaseInitation(data, localStorage.getItem("userToken"))
    //   return orderID;
    // });
  };

  const onApprove = (data, actions) => {
    // actions.order.capture().then(function (details) {
    // let purchaseData = new FormData();
    // purchaseData.append("status", 1)
    // purchaseData.append("order_id", details.id)
    // purchaseData.append("response", JSON.stringify(details))
    props.postPurchaseSuccessFailure(data, localStorage.getItem("userToken"))
    // });

  };
  const onCancel = (data) => {
    // let purchaseData = new FormData();
    // purchaseData.append("status", 2)
    // purchaseData.append("order_id", data.orderID)
    // purchaseData.append("response", JSON.stringify(data))
    props.postPurchaseSuccessFailure(data, localStorage.getItem("userToken"))
  }
  useEffect(() => {
    if (performance.navigation.type === 1 && sessionStorage.getItem("OrderID") !== null) {
      let purchaseData = new FormData();
      purchaseData.append("status", 2)
      purchaseData.append("order_id", sessionStorage.getItem("OrderID"))
      purchaseData.append("response", { "STATUS": "Payment Cancelled By User" })
      props.postPurchaseSuccessFailure(purchaseData, localStorage.getItem("userToken"))
    }
  }, []);

  useEffect(() => {
    if (props.paymentStatus) {
      setCommonAlertModal(true);
      setCommonAlertMessage(
        t("transactionCompletedContent")
      );
      setCommonAlertButtonText(t("continue"));
      setIconName('paymentSuccess');
      setCommonAlertTitle(t("transactionCompleted"));
      setContainLink(false);
      setNavigateLink('');
      setCloseIcon(false);
      props.getFooterData(localStorage.getItem("userToken"));
      props.getHeaderData(localStorage.getItem("userToken"));
      setPayNow(false)
      setPackageLinks(null)
      setPayMethod(0)
      setPaymentData({})
      props.purchaseNull();
      if (sessionStorage.getItem("OrderID") !== null) {
        sessionStorage.removeItem("OrderID");
      };
    } else if (props.paymentStatus === false) {
      setCommonAlertModal(true);
      setCommonAlertMessage(
        t("transactionFailedContent")
      );
      setCommonAlertButtonText(t("backToCoins"));
      setIconName('paymentFailure');
      setCommonAlertTitle(t("transactionFailed"));
      setContainLink(false);
      setNavigateLink('');
      setCloseIcon(false);
      props.purchaseNull();
      setPayNow(false)
      setPackageLinks(null)
      setPayMethod(0)
      setPaymentData({})
      if (sessionStorage.getItem("OrderID") !== null) {
        sessionStorage.removeItem("OrderID");
      };
    }
  }, [props.paymentStatus])

  const cancelPayment = () => {
    setPayNow(false)
    setPaymentData({})
    setPayMethod(0);
    setPackageLinks(null)
  }

  //   const applePay=()=>{
  //     debugger;

  //     var merchantIdentifier = 'merchant.com.iseeju.web';
  //     var promise = window.ApplePaySession.canMakePayments(merchantIdentifier)
  //     promise.then(function (canMakePayments) {
  //       console.log(canMakePayments);
  //       // if (canMakePayments){
  //       //   console.log(canMakePayments+','+window.ApplePaySession?true:false);
  //       // }
  //          // Display Apple Pay button here.
  // });
  //   }

  const styleModal = {
    position: 'absolute' ,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
    textAlign: "center",
  };
  return (
    <Layout subHeader={!isMobile ? false : false} subFooter={!isMobile} header={!isMobile}>
      <Modal
          open={open}
          onClose={handleClose}
      >
        <Box key={"Box"} sx={styleModal}>
          <Typography  style={{textAlign: "center"}}  variant="h6" component="h2">
            Wählen Sie die Zahlungsmethode
          </Typography>
          <Typography    sx={{ mt: 2 }}>
            <Button
                sx={{
                  marginTop: '12px',
                  marginBottom: '10px',
                  width: "50%",

                }}
                onClick={handleMicro}><Micro/></Button>
            <Button
                sx={{
                  marginTop: '12px',
                  marginBottom: '10px',
                  width: "50%"
                }}
                onClick={handlePayPal}><Pp/></Button>
          </Typography>
          <Button

              variant="contained"

              sx={{
                marginTop: '12px',
                marginBottom: '10px',
                alignSelf: "flex-end"
              }}
              onClick={handleClose}
          >
            CLOSE
          </Button>
        </Box>
      </Modal>
      <PaymentAlert
        isContainLink={containLink}
        navigateLink={navigationLink}
        title={commonTitle}
        modal={commonAlertModal}
        message={commonAlertMessage}
        buttonText={commonAlertButtonText}
        icon={IconName}
        removeFunc={setCommonModalFalse}
        closeIcon={closeIcon}
      />
      <Card sx={{ borderRadius: '10px' }}>
        <CardContent>
          <Typography
            variant="h1"
            component="h1"
            sx={{
              color: '#202020',
              textAlign: 'center',
              textTransform: 'none',
              marginBottom: '30px',
            }}
          >
            {t("chooseYourownCoinpackage")}
          </Typography>
        </CardContent>

        <CardContent
          sx={!isMobile ? { padding: '100px !important', paddingTop: '0px !important' } : {}}
        >
          {!payNow ? <Grid container display="flex" justifyContent="space-between">
            {props.packageList.map((item) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={12}
                  xl={6}
                  lg={6}
                  sm={12}
                  key={item}
                  sx={{ maxWidth: '45% !important', paddingBottom: '35px' }}
                  position='relative'
                >
                  {item.id === 1 || item.id === 6 ? (
                    <CornerRibbon
                      position="top-right" // OPTIONAL, default as "top-right"
                      fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                      backgroundColor={item.purchased_status ? "#736c6d" : "#fe456e"} // OPTIONAL, default as "#2c7"
                      containerStyle={{}} // OPTIONAL, style of the ribbon
                      style={{}} // OPTIONAL, style of ribbon content
                      className="" // OPTIONAL, css class of ribbon
                    >
                      <p style={{ margin: '0px !important', marginBlockStart: "0em", marginBlockEnd: "0em", }}> {item.id === 1 ? "TRIAL" : 'HOT'}</p>

                      <p style={{ margin: '0px !important', marginBlockStart: "0em", marginBlockEnd: "0em" }}> {item.id === 1 ? "OFFER" : 'DEAL'}</p>
                    </CornerRibbon>
                  ) : (
                    ''
                  )}
                  <Box
                    sx={{
                      borderLeft: 2,
                      borderRight: 2,
                      borderBottom: 2,
                      borderRadius: '5px',
                      position: 'relative',
                      borderColor: item.purchased_status ? "#736c6d" : `${mockData[item.id]}`,
                    }}
                  // className={item.id === 1 || item.id === 6?classes.ribboncontainer:''}
                  // data-ribbon={item.id === 1 ?`TRIAL\nOFFER`:item.id === 6?"HOT DEAL":''}
                  >
                    {/* {item.id === 1 || item.id === 6 ? (
                      item.id === 1 ? (
                        <TrialRibbon
                          style={{
                            position: 'absolute',
                            top: '-6.5px',
                            left: '77.5%',
                          }}
                        />
                      ) : item.id === 6 ? (
                        <HotRibbon
                          style={{
                            position: 'absolute',
                            top: '-6.5px',
                            left: '77.5%',
                          }}
                        />
                      ) : (
                        ''
                      )
                    ) : (
                      ''
                    )} */}

                    <Card sx={{ borderRadius: '5px' }}>
                      <Typography
                        variant="h4"
                        component="h4"
                        sx={{
                          backgroundColor: item.purchased_status ? "#736c6d" : `${mockData[item.id]}`,
                          textAlign: `${item.id === 1 || item.id === 6 ? 'left' : 'center'
                            }`,
                          padding: '6px 25px',
                        }}
                      >
                        {item.name.toUpperCase()}
                      </Typography>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        borderColor="5px solid red"
                      >
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <img
                            style={{ paddingLeft: '20px', paddingTop: '20px' }}
                            src="/assets/images/iseeju_coins.png"
                            alt="mail"
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography
                            variant="h1"
                            component="span"
                            sx={{
                              textAlign: 'center',
                              // padding: "6px 20px",
                              color: '#202020',
                              fontSize: '31px',
                              fontWeight: '600',
                            }}
                          >
                            €
                          </Typography>
                          <Typography
                            variant="h1"
                            component="span"
                            sx={{
                              textAlign: 'center',
                              // padding: "6px 20px",
                              color: '#202020',
                              fontSize: '50px',
                              fontWeight: '600',
                            }}
                          >
                            {item.price}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center' }}>
                          <Typography
                            variant="h3"
                            component="h3"
                            sx={{
                              textAlign: 'center',
                              paddingBottom: '10px',
                              // marginBottom: "30px",
                            }}
                          >
                            {item.coins} {t("coins")}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} sx={{ display: 'flex', justifyContent: 'center', paddingBottom: '10px', mt: '-15px' }}>
                          <Button
                            variant="contained"
                            color="primary"
                            // className={classes.registerButton}
                            sx={{ backgroundColor: item.purchased_status ? "#736c6d" : '#F4AE18', }}
                            onClick={!item.purchased_status ? () => getPaymentDetails(item) : null}
                          >
                            {t("buyNow")}
                          </Button>
                        </Grid>
                      </Grid>
                    </Card>
                  </Box>
                </Grid>
              );
            })}
          </Grid> :

             payMethod === 1 ?
                <>
              <Grid container>
                <Grid item xs={12} display='flex' justifyContent='center'>
                  <Paypal paymentData={paymentData} postOrderData={createOrder} postTransactionSuccess={onApprove} postTransactionCancel={onCancel} />
                </Grid>

              </Grid>
              {/* <Grid item xs={12} display='flex' justifyContent='center'>
              <Googlepay paymentData={paymentData} postOrderData={createOrder} postTransactionSuccess={onApprove} postTransactionCancel={onCancel}/>
              </Grid>
              <Grid item xs={12} display='flex' justifyContent='center' >
                <Applepay paymentData={paymentData} postOrderData={createOrder} postTransactionSuccess={onApprove} postTransactionCancel={onCancel}/>
              </Grid> */}
              <Grid item xs={12} display='flex' justifyContent='center'>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => cancelPayment()}
                >
                  {t("cancel")}
                </Button>
              </Grid></> : payMethod === 2 ? <div style={{

               margin: "auto",
               textAlign: "center"
                 }}>
                   {packageLinks && packageLinks.length > 0 && (
                       <div>
                         <ul style={{
                           listStyle: "none",
                           margin: "auto",
                           padding: 0,
                           marginBottom: 0

                         }}>
                           {packageLinks.map((link)=>{
                             return (<li onClick={cancelPayment} style={{ margin: 10}}>
                               <a href={link.payment_url} target={"_blank"}><img src={link.image_url} alt={"Payment System"}/></a>
                             </li>)
                           })}
                         </ul>
                       </div>
                   )}


                   {(!packageLinks || packageLinks.length === 0) && (<div
                     style={{
                       margin: "auto",
                       textAlign: "center"
                     }}
                   ><Loader/></div>)}


                   <Button
                     variant="contained"
                     color="secondary"
                     onClick={() => cancelPayment()}
                 >
                   {t("cancel")}
                 </Button></div> : <><Button
                     variant="contained"
                     color="secondary"
                     onClick={() => cancelPayment()}
                 >
                   {t("cancel")}
                 </Button></>}

        </CardContent>
      </Card>
    </Layout >
  );
};

const mapStateToProps = (state) => {
  return {
    selectedTab: state.ProfileTabReducer.selectedTab,
    packageList: state.PackageReducer.packageList,
    paymentStatus: state.PackageReducer.paymentStatus
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageDetails: (requestData) =>
      dispatch(packageAction.getPackageDetails(requestData)),
    postPurchaseInitation: (requestData, token) =>
      dispatch(packageAction.postPurchaseInitation(requestData, token)),
    postPurchaseSuccessFailure: (requestData, token) =>
      dispatch(packageAction.postPurchaseSuccessFailure(requestData, token)),
    getHeaderData: (requestData) =>
      dispatch(headerAction.getHeaderData(requestData)),
    getFooterData: (token) => dispatch(commonAction.getFooterData(token)),
    purchaseNull: (token) => dispatch(packageAction.purchaseNull(token)),

  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CoinPackage);
